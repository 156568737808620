import { Component, OnInit, ViewChild, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { DatePipe, NgIfContext } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// import { Component, OnInit } from '@angular/core';
// import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
// import { ServerService } from '../services/server.service';
import { DomSanitizer } from '@angular/platform-browser';

import Swal from 'sweetalert2';
// import moment from 'moment';
declare var removeports: any;
declare var removefirewallports: any;
var newListjs = [];

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTooltip,
  ApexFill,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";
import * as CanvasJS from '../../assets/js/libs/canvasjs/canvasjs.min';
import { IndexKind, couldStartTrivia } from 'typescript';
import { exit } from 'node:process';
import { encode } from 'node:querystring';
import { log } from 'node:console';
declare var iziToast: any;

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  tooltip: ApexTooltip;
  colors: string[];
  fill: ApexFill;
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
};

declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  openNetDet = false;
  showConsole = false;
  showLaunchPad = false;
  isShowLan = true;
  aioLanDetails: FormGroup;
  aioSIPDetails: FormGroup;
  aioLantype;
  aioLanmac;
  aioLanaddress;
  aioLannetmask;
  aioLangateway;
  netType;
  consoleURL;
  showLaunchPadUrl;
  mainPortsDetails;
  listSipDetails;
  listSipEndpoitDetails
  listrulesDetails;
  isSBC;
  listgroupDetails
  showgroupDetails = false;
  showrulesDetails = false;
  showSipEndpoitDetails = false;
  showlistSipDetails = false;
  singleRuleData;
  addGroupDetails: FormGroup;
  addcallRoutingRuleDetails: FormGroup;
  addcallRoutingRuleDetailsEdit: FormGroup;
  voipForm: FormGroup;
  websocket: any;
  searchtexts;
  userId: any;
  accesstoken: any;
  uuid: any;
  newList = [];
  devicestatus: any;
  deviceactivestatus: any;
  devicesids: any;
  ipaddress = false;
  activatedids: any;
  commentsQuery: QueryRef<any>;
  // comments: Observable<any>;
  loading = true;
  updatelanbuttons = false;
  commonDatas: any = [];
  MemcommDatas = [];
  testing = [];
  sipportvalues: any;
  buttons: any;
  existNotes: any;
  arrayset: any;
  cloudtokenList: any;
  values: any;
  sbcdevices_id: any;
  display_sip_connection: any;
  sbc_index: any;
  formdata: FormGroup;
  sbcformdata: FormGroup;
  sbcupdate: FormGroup;
  sbc_pass_edit: any;
  web_url_edit: any;
  pbx_sip_port_edit: any;
  auth_key_edit: any;
  pbx_tunnel_port_edit: any;
  notesdata: FormGroup;
  firewall_changes: any;
  hostname: any;
  notesidvalue: any;
  notesdetails: any;
  sbcuuid: any;
  login_types: any;
  dist_ids: any;
  dsearchvalue: any;
  checkedvalues = false;
  endusers: any;
  distsearch: any;
  resellersearchList: any;
  endusersearchList: any;
  DistSearchID: any;
  ResellerSearchID: any;
  EndUserSearchID: any;
  allResellerdatas: any;
  allDistdatas: any;
  allEndUserDatas: any;
  userdpath: any;
  idpath: any;
  mailpath: any;
  disti_name: any;
  resellername: any;
  user_page: any;
  showdist = false;
  showreseller = false;
  showRS = false;
  showDIS = false;
  showES = false;
  showend = false;
  super_page = false;
  other_page = false;
  upd_avail = false;
  upd_cpl = false;
  lic_id_array: any = [];
  searcharr: any = [];
  searchtextval;
  ResellerdistID;
  total_records: any;
  offset;
  limits;
  pages_num: any = [];
  default_page;
  paginationData: any = { "info": "hide" };

  // testingvalues: number[] = [721,322,574,434,512];
  // cpu_time = [];
  // cpu_datas = [];
  private querySubscription: Subscription;
  IP_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  comments: any;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public MemorychartOptions: Partial<ChartOptions>;
  macvalue1: any;

  constructor(public datepipe: DatePipe, private apollo: Apollo, private apiservice: ServicesService, public router: Router) {
    this.accesstoken = localStorage.getItem('access_token');
    this.login_types = localStorage.getItem('login_types');
    // this.hostname = $(location).attr('hostname');
    this.hostname = 'localhost';


  }


  ngOnInit(): void {
    localStorage.setItem("all_dist_data", "admin")
    // localStorage.setItem("all_dist_data","all")
    localStorage.setItem('previous_dist_id', null)
    localStorage.setItem('previous_reseller_id', '0')
    this.userId = localStorage.getItem('customerId');
    this.dist_ids = localStorage.getItem('distributor_id');
    this.endusers = localStorage.getItem('end_user');
    this.mailpath = localStorage.getItem('login_email_id');
    this.idpath = localStorage.getItem('customerId');
    this.userdpath = localStorage.getItem('user_identity');
    this.user_page = localStorage.getItem('login_email_id');
    this.limits = 10;
    this.default_page = 1;
    if(this.user_page == 'superadmin'){
      this.super_page = true;
      this.other_page = false;
    } else {
      this.super_page = false;
      this.other_page = true;
    }
    if (this.userId == '5505' && this.login_types == 'D') {
      this.showdist = true;
      this.showreseller = true;
      this.showend = true;
    } else if (this.login_types == 'D') {
      this.showdist = false;
      this.showreseller = true;
      this.showend = true;
    } else if (this.login_types == 'R') {
      if (this.endusers == 0) {
        this.showdist = false;
        this.showreseller = false;
        this.showend = true;
      } else if (this.endusers == 1) {
        this.showdist = false;
        this.showreseller = false;
        this.showend = false;
      }
    }
    this.justOnce();
    // this.cpu_performance();
    this.initSocket();
    this.searchlists();
    this.loginDisplayDevices();
    this.userpath();
    setTimeout(() => {
      $("#pageval" + this.default_page).addClass("active");
    }, 3000);
    // this.hellotesting();

    // $('.expand-button').click(()=>{
    //   $(".tt").hide();
    // });


    this.formdata = new FormGroup({
      sbcpass: new FormControl(""),
      sbcweb: new FormControl(""),
      sbckeys: new FormControl(""),
      sbcport: new FormControl(""),
      sbcsipport: new FormControl(""),
      sbctunnelport: new FormControl(""),

    });

    this.sbcformdata = new FormGroup({
      sbcpass1: new FormControl(""),
      sbcweb1: new FormControl(""),
      sbckeys1: new FormControl(""),
      sbcport1: new FormControl(""),
      sbcsipport1: new FormControl(""),
      sbctunnelport1: new FormControl(""),

    });

    this.sbcupdate = new FormGroup({
      sbcpass2: new FormControl(""),
      sbcweb2: new FormControl(""),
      sbckeys2: new FormControl(""),
      sbcport2: new FormControl(""),
      sbcsipport2: new FormControl(""),
      sbctunnelport2: new FormControl(""),

    });

    this.notesdata = new FormGroup({
      notesvalues: new FormControl(""),
    });

    this.aioLanDetails = new FormGroup({
      mac: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      netmask: new FormControl(null, Validators.required),
      def_gateway: new FormControl(null, Validators.required)
    });
    this.addGroupDetails = new FormGroup({
      group_name: new FormControl(null, Validators.required),
      group_type: new FormControl(null, Validators.required),
      group_policy: new FormControl(null, Validators.required)
    });
    this.aioSIPDetails = new FormGroup({
      sipuser: new FormControl(null, Validators.required),
      sipname: new FormControl(null, Validators.required),
      siphost: new FormControl(null, Validators.required),
      sipassword: new FormControl(null, Validators.required),
      sipport: new FormControl(null, Validators.required),
      sipauthUser: new FormControl(null, Validators.required),
      sipregUser: new FormControl(null, Validators.required)
    });
    this.addcallRoutingRuleDetails = new FormGroup({
      route_name: new FormControl(null, Validators.required),
      call_from: new FormControl(null, Validators.required),
      call_send_through: new FormControl(null, Validators.required),
      force_answer: new FormControl(null, Validators.required),
      secondary_dialing: new FormControl(null, Validators.required),
      disa_timeout: new FormControl(null, Validators.required),
      authentication: new FormControl(null, Validators.required),
      max_pass_digit: new FormControl('10', Validators.required),
      password: new FormControl(null, Validators.required),
      forword_number: new FormControl(null, Validators.required),
      dialing_delay: new FormControl(null, Validators.required),
      custom_context: new FormControl(null, Validators.required),
      t_gateway_mode: new FormControl(null, Validators.required),
      failover_call_number: new FormControl(null, Validators.required)
    });
    this.addcallRoutingRuleDetailsEdit = new FormGroup({
      route_nameEdit: new FormControl(null, Validators.required),
      call_fromEdit: new FormControl(null, Validators.required),
      call_send_throughEdit: new FormControl(null, Validators.required),
      force_answerEdit: new FormControl(null, Validators.required),
      secondary_dialingEdit: new FormControl(null, Validators.required),
      disa_timeoutEdit: new FormControl(null, Validators.required),
      authenticationEdit: new FormControl(null, Validators.required),
      max_pass_digitEdit: new FormControl('10', Validators.required),
      passwordEdit: new FormControl(null, Validators.required),
      forword_numberEdit: new FormControl(null, Validators.required),
      dialing_delayEdit: new FormControl(null, Validators.required),
      custom_contextEdit: new FormControl(null, Validators.required),
      t_gateway_modeEdit: new FormControl(null, Validators.required),
      failover_call_numberEdit: new FormControl(null, Validators.required)
    });
    this.voipForm = new FormGroup({
      name: new FormControl(),
      sip_enable: new FormControl(),
      user_name: new FormControl(),
      password: new FormControl(),
      allowanonymous: new FormControl(),
      registration: new FormControl('0'),
      ipAddress: new FormControl(),
      transPort: new FormControl('0'),
      NATtraversal: new FormControl('2'),
      subscribeMWI: new FormControl('0'),
      stunSwitch: new FormControl(),
      priotiyMatch: new FormControl(),
      enable_Outboundproxy: new FormControl('0'),
      authenticateUser: new FormControl(),
      RegExtension: new FormControl(),
      RegUser: new FormControl(),
      formUser: new FormControl(),
      backupHostnameIPAddress: new FormControl(),
      formDomain: new FormControl(),
      remoteSecret: new FormControl(),
      port: new FormControl(),
      qualify: new FormControl('0'),
      qualifyFrequency: new FormControl('60'),
      outBoundproxyport: new FormControl('5060'),
      Prack: new FormControl(),
      telURL: new FormControl(),
      IMSvolte: new FormControl(),
      outBoundproxy: new FormControl(),
      customRegistry: new FormControl('0'),
      registry_string: new FormControl(),
      callLimit: new FormControl('4'),
      remotePartyITFormat: new FormControl('0'),
      DtmfMode: new FormControl('0'),
      trustRemoteParty: new FormControl('0'),
      sendRemote: new FormControl('0'),
      callerIdPresentation: new FormControl('0'),
      progressInband: new FormControl('0'),
      allowOverlapDialling: new FormControl('0'),
      appendUserUrl: new FormControl('0'),
      addReasonHeader: new FormControl('0'),
      honorSDPversion: new FormControl('1'),
      directMedia: new FormControl(),
      allowTransfer: new FormControl('1'),
      allowPermisRedirect: new FormControl('0'),
      vosEncryption: new FormControl('0'),
      maxForward: new FormControl(70),
      sendTrytoReg: new FormControl('0'),
      defaultTimer: new FormControl(500),
      callSetupTimer: new FormControl(32000),
      sessionTimer: new FormControl('0'),
      HonorSDP: new FormControl(),
      minSessionRef: new FormControl(90),
      maxSessionRef: new FormControl(1800),
      sessionRef: new FormControl('1'),
      protocol: new FormControl(),
      codecPriority1: new FormControl(),
      codecPriority2: new FormControl(),
      codecPriority3: new FormControl(),
      codecPriority4: new FormControl(),
      codecPriority5: new FormControl(),
      enabled: new FormControl(),
      registertrying: new FormControl('0'),
      'action': new FormControl("createVoipEndpoints")
    });
    this.cpu_performance({});

  }




  getsearch(l) {

    this.searchtextval = l.mac_address1;
    this.loginDisplayDevices()

  }
  // loginDisplayDevices() {
  //   if (this.login_types == 'D') {
  //     this.LicenseList({});

  //   } else if (this.login_types == 'R') {
  //     this.resellerlicenselist();
  //   }
  // } 
  loginDisplayDevices() {
    if (this.login_types === 'D') {
      this.LicenseList();

    } else if (this.login_types === 'R') {
      this.resellerlicenselist();
    }
  }

  validateIP(ip: string) {
    var result = this.IP_REGEXP.test(ip);
    return result;


  }
  searchlists() {

    if (this.userId == '5505' && this.login_types == 'D') {
      this.getdistsearchlist();
      this.showDIS = true;
      this.showRS = true;
      this.showES = true;
    } else if (this.login_types == 'D') {
      // this.getdistsearchlist();
      this.showRS = true;
      this.showES = false;
      this.showDIS = false;
      this.getresellersearchlist();
    } else if (this.login_types == 'R') {
      this.showRS = false;
      this.showES = true;
      this.showDIS = false;
      this.getresellersearchlist();
    }
  }
  userpath() {
    const datap: any = new Object();
    const testdeails: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': datap,
    });
    datap.action = 'getPathdata';
    var id = this.idpath;
    // alert(id);
    datap.user_id = btoa(id);
    datap.email = this.mailpath;
    datap.type = this.userdpath;

    this.apiservice.newsendServer(testdeails).then((response: any) => {
      // alert("insider");
      this.disti_name = response.distributor;
      this.resellername = response.reseller;
      localStorage.setItem('distri_name', this.disti_name);
      localStorage.setItem('resell_name', this.resellername);
    });
    // alert(this.disti_name);

  }
  getdistsearchlist() {
    // {"access_token":"","api_type":"web","operation":"curlData",
    // "moduleType":"reseller","element_data":{"action":"distributor_list"}}

    const getDistListReq: any = new Object();
    const distList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': getDistListReq,
    });

    getDistListReq.action = 'distributor_list';

    this.apiservice.newsendServer(distList).then((response: any) => {
      if (response.result_code == '111') {
        this.distsearch = response.result_data;
      }
      console.log(response);
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();  
    });
  }

  onChangeDist(events) {
    //console.log(events);
    // var eventsarr = events.split(',');
    // //console.log(eventsarr);
    // this.ResellerdistID = eventsarr[1];

    this.allDistdatas = events;
    //console.log(this.allDistdatas);
    if (events === 'all') {
      this.DistSearchID = '';
      this.offset = 0;
    } else {
      this.DistSearchID = events;
    }

    const selectionDistListReq: any = new Object();
    const distList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': selectionDistListReq,
    });

    selectionDistListReq.action = 'get_reseller';
    selectionDistListReq.dist_id = this.DistSearchID;
    selectionDistListReq.limit = 10;
    selectionDistListReq.end_user = '0';


    this.apiservice.newsendServer(distList).then((response: any) => {
      if (response.result_code == '111') {
        this.resellersearchList = response.data;

        this.total_records = response.tot_records;
        this.offset = response.offset;
        this.pages_num = [];
        // var round_val = Math.ceil(this.total_records / 10);
        // for (var i = 0; i < 10; i++) {
        //   this.pages_num.push(i + 1);
        // }




        var round_val = Math.ceil(response.total_records / 20);
        for (var i = 0; i < round_val; i++) {
          if (i > 9) {
            break;
          } else {
            this.pages_num.push(i + 1);
          }
        }


        //         $('#pageval1').addClass('active');
        // $('#pageval'+this.default_page).removeClass('active');

      } else if (response.result_code == '222') {
        this.resellersearchList = [];
        //     if (events == 'all') {
        // this.showRS = true
        //     }else{
        //       this.showRS = false;
        //     }
      }
      console.log(response);
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  onChangeReseller(events) {
    //console.log(events);
    var eventsarr = events.split(',');
    //console.log(eventsarr);
    this.ResellerdistID = eventsarr[1];
    this.allResellerdatas = eventsarr[0];
    if (events == 'all') {
      this.ResellerSearchID = '';
    } else {
      this.ResellerSearchID = events;
    }

    const selectionResellerListReq: any = new Object();
    const distList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': selectionResellerListReq,

    });

    selectionResellerListReq.action = 'get_reseller';
    selectionResellerListReq.dist_id = this.ResellerSearchID;
    selectionResellerListReq.end_user = '1';
    selectionResellerListReq.reseller_id = this.userId;

    this.apiservice.newsendServer(distList).then((response: any) => {
      if (response.result_code == '111') {
        this.endusersearchList = response.data;
      } else if (response.result_code == '222') {
        this.endusersearchList = [];
      }
      console.log(response);
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  onChangeEndUser(events) {
    //console.log(events);
    this.allEndUserDatas = events;
    if (events == 'all') {
      this.EndUserSearchID = '';
    } else {
      this.EndUserSearchID = events;
      // this.ResellerSearchID = events;
    }
  }

  // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller","element_data":{"action":"auto_fill","customer_id":"3","search_text":"B","type":"1"}}
  // changetext(val){
  //   //console.log(val)


  // }
  changetext(text) {
    this.searchtextval = text;
    this.searcharr = [];
    if (text != '') {
      const searchButtonList: any = new Object();
      var type;
      if (localStorage.getItem('end_user')) {
        type = localStorage.getItem('end_user')
      } else {
        type = '2';

      }
      searchButtonList.action = 'auto_fill';
      searchButtonList.customer_id = this.userId;
      // searchButtonList.customer_id = '10';
      searchButtonList.search_text = text;
      searchButtonList.type = type;


      // alert(searchButtonList.type);
      const wholeSearchList: any = ({
        'access_token': this.accesstoken,
        'api_type': 'web',
        'operation': 'curlData',
        'moduleType': 'reseller',
        'element_data': searchButtonList,
      });

      this.apiservice.newsendServer(wholeSearchList).then((response: any) => {
        //console.log(response);
        this.searcharr = response.data;
        this.loginDisplayDevices()
        // if (response.result_code == '111') {
        // }
        // //console.log(response);
      }, (error) => {
        //console.log(error);
        // this.apiservice.dismiss();
      });



    } else {
      this.loginDisplayDevices()
    }
  }



  // $(document).ready(function() {
  //   $(#expand-button).click(function() {
  //     $(.tt).hide();
  //   });
  // });



  // 

  searchButton() {

    // {"access_token":"","api_type":"web","operation":"curlData",
    // "moduleType":"reseller","element_data":{"action":"device_list",
    // "dist_id":"349","reseller_id":"1","end_user":"","limit":"10","offset":"0"}}
    // localStorage.setItem('all_dist_data','all');

    if (this.allDistdatas === 'all' || this.allEndUserDatas === 'all' || this.allResellerdatas === 'all') {
      //console.log(this.allDistdatas);
      this.newList = [];
      this.offset = 0;
      localStorage.setItem('all_dist_data', 'all');
      this.loginDisplayDevices();
    } else {
      //console.log(this.DistSearchID);
      localStorage.setItem('all_dist_data', this.DistSearchID);

      // if (localStorage.getItem("previous_dist_id") === null) {
      //     localStorage.setItem('previous_dist_id', this.DistSearchID);
      // }

      if (localStorage.getItem("previous_reseller_id") === null) {
        localStorage.setItem('previous_reseller_id', this.DistSearchID);
      }



      //console.log(this.ResellerSearchID);
      //console.log(this.EndUserSearchID);

      const searchButtonList: any = new Object();
      const wholeSearchList: any = ({
        'access_token': this.accesstoken,
        'api_type': 'web',
        'operation': 'curlData',
        'moduleType': 'reseller',
        'element_data': searchButtonList,
      });

      searchButtonList.action = 'device_list';
      if (this.userId == '5505' && this.login_types == 'D') {
        if (this.DistSearchID == undefined) {
          searchButtonList.dist_id = '';
        } else {
          searchButtonList.dist_id = this.DistSearchID;
        }
      } else {
        // alert(this.ResellerdistID);
        searchButtonList.dist_id = this.ResellerdistID;

      }

      if (this.ResellerSearchID == undefined) {

        if (this.login_types == 'R') {
          searchButtonList.reseller_id = this.userId;
        } else {
          searchButtonList.reseller_id = '';
        }
        // alert("in");
      } else {
        searchButtonList.reseller_id = this.ResellerSearchID;
      }
      if (this.EndUserSearchID == undefined) {
        searchButtonList.end_user = 'in';
      } else {
        searchButtonList.end_user = this.EndUserSearchID;
      }

      if (this.newList.length == 0 || this.offset == '' || this.offset == undefined) {
        this.offset = 0;
      }



      if (this.ResellerSearchID != localStorage.getItem("previous_reseller_id")) {
        localStorage.setItem('previous_reseller_id', this.ResellerSearchID);

        var ted = localStorage.getItem("previous_reseller_id");

        if (ted == undefined || ted == 'undefined') {
          // alert(localStorage.getItem("previous_reseller_id"))
          //this.offset = 0;
        } else {
          this.offset = 0;
        }
      }

      if (this.offset == 0) {
        $('#pageval1').addClass('active');
        $('#pageval' + this.default_page).removeClass('active');
      }

      searchButtonList.limit = this.limits;
      searchButtonList.offset = this.offset;
      // alert(this.offset)



      this.apiservice.newsendServer(wholeSearchList).then((response: any) => {

        if (response.data == "") {
          Swal.fire({

            // title: 'Failed',
            text: 'No Device found',
            icon: 'error',
            timer: 2000
          });
        }

        if (response.result_code == '111') {

          this.newList = response.license_list;

          this.pages_num = [];
          // var round_val = Math.ceil(this.total_records / 10);
          // for (var i = 0; i < 10; i++) {
          //   this.pages_num.push(i + 1);

          // }
          this.paginationData = this.apiservice.pagination({ 'offset': response.offset, 'total': response.total_records, 'page_limit': this.limits });

          var round_val = Math.ceil(response.total_records / 20);
          for (var i = 0; i < round_val; i++) {
            if (i > 9) {
              break;
            } else {
              this.pages_num.push(i + 1);
            }
          }

          // $('#pageval1').addClass('active');
          // $('#pageval'+this.default_page).removeClass('active');


          this.newList = response.data;
          //console.log(this.newList);
          //console.log(this.newList.length);
          this.newList.forEach(values => {
            var mac1 = values.mac_address1;
            var mac2 = values.mac_address2;
            var sno = values.serial_number;
            var dev_model = values.device_model;

            //console.log(values.device_model);
            values.cpu = "";
            values.memory = "";
            values.temp = [];

            var wanip = mac1.toLowerCase();
            var id = btoa('WAN=' + wanip);
            var testid = btoa(id);
            this.lic_id_array.push(testid);
            // this.getdevicestatus();
            this.sendOnload(mac1);

            // this.getnewdevices(sno, mac1, mac2, dev_model);
          });
          //console.log(this.lic_id_array);
        } else if (response.status == 'false') {
          this.newList = [];
          let messages = 'Failed';
          let texts = 'No devices Found';
          this.errortoastForm(messages, texts);


        }
        console.log(response);
      }, (error) => {
        console.log(error);
        // this.apiservice.dismiss();
      });

    }
  }

  newdeviceList() {

    const searchButtonList: any = new Object();
    const wholeSearchList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': searchButtonList,
    });


    searchButtonList.action = 'device_list';
    searchButtonList.dist_id = this.DistSearchID;

    if (this.userId == '5505' && this.login_types == 'D') {
      if (this.DistSearchID == undefined) {
        searchButtonList.dist_id = '';
      } else {
        searchButtonList.dist_id = this.DistSearchID;
      }
    } else {
      // alert(this.ResellerdistID);
      searchButtonList.dist_id = this.ResellerdistID;

    }

    if (this.ResellerSearchID == undefined) {

      if (this.login_types == 'R') {
        searchButtonList.reseller_id = this.userId;
      } else {
        searchButtonList.reseller_id = '';
      }

    } else {
      searchButtonList.reseller_id = this.ResellerSearchID;
    }
    if (this.EndUserSearchID == undefined) {
      searchButtonList.end_user = 'in';
    } else {
      searchButtonList.end_user = this.EndUserSearchID;
    }
    //console.log(searchButtonList.reseller_id);

    // return false;
    if (this.newList.length == 0) {
      this.offset = 0;
    }

    if (this.offset == 0) {
      $('#pageval1').addClass('active');
      $('#pageval' + this.default_page).removeClass('active');
    }


    searchButtonList.limit = this.limits;
    searchButtonList.offset = this.offset;
    this.apiservice.newsendServer(wholeSearchList).then((response: any) => {

      //console.log(response);


      if (response.result_code == '111') {
        this.newList = response.data;
        this.total_records = response.total_records;
        this.offset = response.offset;
        this.pages_num = [];



        var round_val = Math.ceil(response.total_records / 20);
        for (var i = 0; i < round_val; i++) {
          if (i > 9) {
            break;
          } else {
            this.pages_num.push(i + 1);
          }
        }


        //console.log(this.pages_num);
        //console.log(this.newList);
        //console.log(this.newList.length);

        this.newList.forEach(values => {
          var mac1 = values.mac_address1;
          var mac2 = values.mac_address2;
          var sno = values.serial_number;
          var dev_model = values.device_model;
          values.cpu = "";
          values.memory = "";
          values.temp = [];
          // values.uid =values.serial_number;
          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // alert('testing');
          // this.lic_id_array.push(testid);
          // //console.log(this.lic_id_array);
          // this.getdevicestatus();
          this.sendOnload(mac1);
          // this.cpu_performance(mac1);

          // this.getnewdevices(sno, mac1, mac2, dev_model);
        });
        //console.log(this.lic_id_array);

        $('#lic_id_array').val(this.lic_id_array);
        // Swal.close()

      } else if (response.status == 'false') {
        // this.showfalsestate = true;
        // Swal.close()
      }
    }, (error) => {

      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  getresellersearchlist() {
    // {"access_token":"","api_type":"web","operation":"get_reseller",
    // "moduleType":"reseller","element_data":{"action":"get_reseller",
    // "dist_id":"349","end_user":"0"}}
    const getResellerListReq: any = new Object();
    const distList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': getResellerListReq,
    });

    getResellerListReq.action = 'get_reseller';
    if (this.login_types == 'D') {
      getResellerListReq.dist_id = this.userId;
    } else {
      getResellerListReq.dist_id = this.dist_ids;
    }

    if (this.login_types == 'D') {
      getResellerListReq.end_user = '0';
    } else if (this.login_types == 'R') {
      // getResellerListReq.end_user = this.endusers;
      getResellerListReq.end_user = '1';
      getResellerListReq.reseller_id = this.userId;
    }

    this.apiservice.newsendServer(distList).then((response: any) => {
      if (response.success_state == 'Success') {
        //console.log(this.endusers);
        if (this.login_types == 'D') {
          this.resellersearchList = response.data;
          //console.log(response.data);
        } else if (this.login_types == 'R') {
          this.endusersearchList = response.data;
        }
      }
      console.log(response);
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  getalluserlist() {
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'superadmin_license_list';
    // ProfiledetailsReq.customer_id = this.userId;
    // ProfiledetailsReq.customer_id = '349';
    ProfiledetailsReq.limit = '50';
    ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      console.log(response);
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

  // getthecloudtokens() {
  //   this.apiservice.watchQuerys({ user_id: this.userId }, LIST_CLOUD_TOKENS).valueChanges.subscribe(({ data }) => {
  //     const todosData: any = data;
  //     // this.cloudtokenList = todosData.device;
  //     // //console.log(this.cloudtokenList);
  //     this.getdevicestatus();
  //     // mac_id_1: "Alliance"
  //     // mac_id_2: "StarAli"
  //     // serial_number: "Startegic"
  //     // uuid: "272ce6b7-5b31-4779-9d00-a1b627eeb812"

  //     // setTimeout(() => {
  //     //   // var testing = this.cloudtokenList.concat(this.newList);
  //     //   // //console.log(testing);
  //     //   // //console.log(todosData.device);
  //     //   //console.log(this.newList);
  //     //   const result = {
  //     //     ...this.cloudtokenList,
  //     //     ...this.newList,
  //     //   };
  //     //   //console.log(result);
  //     // }, 5000);
  //   }, (error) => {
  //     //console.log('there was an error sending the query', error);
  //   });
  // }


  justOnce() {
    if (!localStorage.justOnce) {
      localStorage.setItem("justOnce", "true");
      window.location.reload();
    }
  }

  // onClickSubmit(data) {
  //   this.formdata.reset();
  //   alert("testing");
  //   //console.log(data);
  // }

  toast(messages) {
    // iziToast.show({
    //   message: messages,
    //   position: 'topCenter',
    //   timeout: 2000,
    //   color: 'green',
    //   theme: 'light'

    // });

    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: messages,
      icon: 'success',
      showConfirmButton: false,
      timer: 2000
    });

    // Swal.fire({
    //   position: 'top-end',
    //   icon: 'success',
    //   title: 'Your work has been saved',
    //   showConfirmButton: false,
    //   timer: 1500
    // })

  }

  Testingsort(index, expand) {

    if (expand == 'expand') {
      this.cpu_performance(index);

    }
    this.macvalue1 = index
    //console.log(index);

    // $('#expand-button-1'+index).click(function() {
    $(".hide-block-1" + index).toggle();
    $(".sort-icon-1" + index).toggle();
    $(".expand-icon-1" + index).toggle();
    // });
  }



  // defaultsort(index){
  //   //console.log(index);
  //   $('#expand-button-1'+index).click(function() {
  //       $(".hide-block-1"+index).toggle();
  //        $(".sort-icon-1"+index).toggle();
  //         $(".expand-icon-1"+index).toggle();
  //   });
  // }  
  toastForm(messages, texts) {
    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: messages,
      text: texts,
      icon: 'success',
      showConfirmButton: false,
      timer: 2000
    });
  }

  errortoast(messages) {
    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: messages,
      icon: 'warning',
      showConfirmButton: false,
      timer: 2000
    });
  }
  errortoastForm(messages, texts) {
    Swal.fire({

      title: messages,
      text: texts,
      icon: 'error',
      showConfirmButton: false,
      timer: 2000
    });
  }


  ObtainRRToggle(devices_id, index, mac_address) {
    // <!-----------------------------------RR--LAN1--------------------
    //console.log(index)
    var enabled = $("#rrLanAutoIP" + index).is(":checked");
    //console.log(enabled)
    if (enabled == true) {

      $("#rrlan1address1" + mac_address).prop('disabled', false);
      $("#rrlan1address2" + mac_address).prop('disabled', false);
      $("#rrlan1address3" + mac_address).prop('disabled', false);
      $("#rrlan1address4" + mac_address).prop('disabled', false);

      $("#rrlan1netmask1" + mac_address).prop('disabled', false);
      $("#rrlan1netmask2" + mac_address).prop('disabled', false);
      $("#rrlan1netmask3" + mac_address).prop('disabled', false);
      $("#rrlan1netmask4" + mac_address).prop('disabled', false);

      $("#rrlan1gateway1" + mac_address).prop('disabled', false);
      $("#rrlan1gateway2" + mac_address).prop('disabled', false);
      $("#rrlan1gateway3" + mac_address).prop('disabled', false);
      $("#rrlan1gateway4" + mac_address).prop('disabled', false);


    } else {

      $("#rrlan1address1" + mac_address).prop('disabled', true);
      $("#rrlan1address2" + mac_address).prop('disabled', true);
      $("#rrlan1address3" + mac_address).prop('disabled', true);
      $("#rrlan1address4" + mac_address).prop('disabled', true);


      $("#rrlan1netmask1" + mac_address).prop('disabled', true);
      $("#rrlan1netmask2" + mac_address).prop('disabled', true);
      $("#rrlan1netmask3" + mac_address).prop('disabled', true);
      $("#rrlan1netmask4" + mac_address).prop('disabled', true);

      $("#rrlan1gateway1" + mac_address).prop('disabled', true);
      $("#rrlan1gateway2" + mac_address).prop('disabled', true);
      $("#rrlan1gateway3" + mac_address).prop('disabled', true);
      $("#rrlan1gateway4" + mac_address).prop('disabled', true);
    }

    // <!-----------------------------------RR--LAN2--------------------

    //console.log(index)
    var enabled = $("#rrLan2AutoIP" + index).is(":checked");
    //console.log(enabled)
    if (enabled == true) {

      $("#rrlan2address1" + mac_address).prop('disabled', false);
      $("#rrlan2address2" + mac_address).prop('disabled', false);
      $("#rrlan2address3" + mac_address).prop('disabled', false);
      $("#rrlan2address4" + mac_address).prop('disabled', false);

      $("#rrlan2netmask1" + mac_address).prop('disabled', false);
      $("#rrlan2netmask2" + mac_address).prop('disabled', false);
      $("#rrlan2netmask3" + mac_address).prop('disabled', false);
      $("#rrlan1netmask4" + mac_address).prop('disabled', false);

      $("#rrlan2gateway1" + mac_address).prop('disabled', false);
      $("#rrlan2gateway2" + mac_address).prop('disabled', false);
      $("#rrlan2gateway3" + mac_address).prop('disabled', false);
      $("#rrlan2gateway4" + mac_address).prop('disabled', false);


    } else {

      $("#rrlan2address1" + mac_address).prop('disabled', true);
      $("#rrlan2address2" + mac_address).prop('disabled', true);
      $("#rrlan2address3" + mac_address).prop('disabled', true);
      $("#rrlan2address4" + mac_address).prop('disabled', true);


      $("#rrlan2netmask1" + mac_address).prop('disabled', true);
      $("#rrlan2netmask2" + mac_address).prop('disabled', true);
      $("#rrlan2netmask3" + mac_address).prop('disabled', true);
      $("#rrlan2netmask4" + mac_address).prop('disabled', true);

      $("#rrlan2gateway1" + mac_address).prop('disabled', true);
      $("#rrlan2gateway2" + mac_address).prop('disabled', true);
      $("#rrlan2gateway3" + mac_address).prop('disabled', true);
      $("#rrlan2gateway4" + mac_address).prop('disabled', true);
    }



    // ------------------------------------------------------------>


  }
  // ObtainRRToggle2(devices_id, index, mac_address) {

  //   //console.log(index)
  //   var enabled = $("#rrLanAutoIP" + index).is(":checked");
  //   //console.log(enabled)
  //   if (enabled == true) {

  //     $("#rrlan1address1" + mac_address).prop('disabled', false);
  //     $("#rrlan1address2" + mac_address).prop('disabled', false);
  //     $("#rrlan1address3" + mac_address).prop('disabled', false);
  //     $("#rrlan1address4" + mac_address).prop('disabled', false);

  //     $("#rrlan1netmask1" + mac_address).prop('disabled', false);
  //     $("#rrlan1netmask2" + mac_address).prop('disabled', false);
  //     $("#rrlan1netmask3" + mac_address).prop('disabled', false);
  //     $("#rrlan1netmask4" + mac_address).prop('disabled', false);

  //     $("#rrlan1gateway1" + mac_address).prop('disabled', false);
  //     $("#rrlan1gateway2" + mac_address).prop('disabled', false);
  //     $("#rrlan1gateway3" + mac_address).prop('disabled', false);
  //     $("#rrlan1gateway4" + mac_address).prop('disabled', false);


  //   } else {

  //     $("#rrlan1address1" + mac_address).prop('disabled', true);
  //     $("#rrlan1address2" + mac_address).prop('disabled', true);
  //     $("#rrlan1address3" + mac_address).prop('disabled', true);
  //     $("#rrlan1address4" + mac_address).prop('disabled', true);


  //     $("#rrlannetmask1" + mac_address).prop('disabled', true);
  //     $("#rrlannetmask2" + mac_address).prop('disabled', true);
  //     $("#rrlannetmask3" + mac_address).prop('disabled', true);
  //     $("#rrlannetmask4" + mac_address).prop('disabled', true);

  //     $("#rrlangateway1" + mac_address).prop('disabled', true);
  //     $("#rrlangateway2" + mac_address).prop('disabled', true);
  //     $("#rrlangateway3" + mac_address).prop('disabled', true);
  //     $("#rrlangateway4" + mac_address).prop('disabled', true);
  //   }
  // }

  ObtainToggle(devices_id, index, mac_address) {

    //console.log('ObtainToggle....', index)
    //console.log('ObtainToggle....', mac_address)
    var enabled = $("#LanAutoIP" + mac_address).is(":checked");
    // console.log('ObtainToggle', enabled)
    if (enabled == false) {

      $("#lanaddress1" + mac_address).prop('disabled', false);
      $("#lanaddress2" + mac_address).prop('disabled', false);
      $("#lanaddress3" + mac_address).prop('disabled', false);
      $("#lanaddress4" + mac_address).prop('disabled', false);

      $("#lannetmask1" + mac_address).prop('disabled', false);
      $("#lannetmask2" + mac_address).prop('disabled', false);
      $("#lannetmask3" + mac_address).prop('disabled', false);
      $("#lannetmask4" + mac_address).prop('disabled', false);

      $("#langateway1" + mac_address).prop('disabled', false);
      $("#langateway2" + mac_address).prop('disabled', false);
      $("#langateway3" + mac_address).prop('disabled', false);
      $("#langateway4" + mac_address).prop('disabled', false);


    } else {
      // $(".input-disable" + index).prop('disabled', true);
      // $(".input-disable" + index).attr('readonly', true); 
      $("#lanaddress1" + mac_address).prop('disabled', true);
      $("#lanaddress2" + mac_address).prop('disabled', true);
      $("#lanaddress3" + mac_address).prop('disabled', true);
      $("#lanaddress4" + mac_address).prop('disabled', true);


      $("#lannetmask1" + mac_address).prop('disabled', true);
      $("#lannetmask2" + mac_address).prop('disabled', true);
      $("#lannetmask3" + mac_address).prop('disabled', true);
      $("#lannetmask4" + mac_address).prop('disabled', true);

      $("#langateway1" + mac_address).prop('disabled', true);
      $("#langateway2" + mac_address).prop('disabled', true);
      $("#langateway3" + mac_address).prop('disabled', true);
      $("#langateway4" + mac_address).prop('disabled', true);
    }
  }

  followip(devices_id, index, mac_address) {

    //console.log('followip....', index)
    //console.log('followip....', mac_address)


    var enabled = $("#LanAssignIP" + mac_address).is(":checked");
    // console.log('followip', enabled)

    if (enabled == false) {
      // $(".input-disable" + index).removeAttr('disabled');
      // $(".input-disable" + index).attr('disabled', true);
      $("#lanaddress1" + mac_address).prop('disabled', true);
      $("#lanaddress2" + mac_address).prop('disabled', true);
      $("#lanaddress3" + mac_address).prop('disabled', true);
      $("#lanaddress4" + mac_address).prop('disabled', true);


      $("#lannetmask1" + mac_address).prop('disabled', true);
      $("#lannetmask2" + mac_address).prop('disabled', true);
      $("#lannetmask3" + mac_address).prop('disabled', true);
      $("#lannetmask4" + mac_address).prop('disabled', true);

      $("#langateway1" + mac_address).prop('disabled', true);
      $("#langateway2" + mac_address).prop('disabled', true);
      $("#langateway3" + mac_address).prop('disabled', true);
      $("#langateway4" + mac_address).prop('disabled', true);


    } else {
      // $(".input-disable" + index).attr('disabled', false);
      // $(".input-disable" + index).attr('readonly', false); 
      $("#lanaddress1" + mac_address).prop('disabled', false);
      $("#lanaddress2" + mac_address).prop('disabled', false);
      $("#lanaddress3" + mac_address).prop('disabled', false);
      $("#lanaddress4" + mac_address).prop('disabled', false);

      $("#lannetmask1" + mac_address).prop('disabled', false);
      $("#lannetmask2" + mac_address).prop('disabled', false);
      $("#lannetmask3" + mac_address).prop('disabled', false);
      $("#lannetmask4" + mac_address).prop('disabled', false);

      $("#langateway1" + mac_address).prop('disabled', false);
      $("#langateway2" + mac_address).prop('disabled', false);
      $("#langateway3" + mac_address).prop('disabled', false);
      $("#langateway4" + mac_address).prop('disabled', false);

      // $(".input-disable" + index).attr('disabled','disabled');
      // $(".input-disable" + index).prop('disabled', true);
    }

  }
  ObtainWAN(devices_id, index, mac_address) {

    //console.log('ObtainWAN....', index)
    //console.log('ObtainWAN....', mac_address)

    var enabled = $("#WanAutoIP" + mac_address).is(":checked");
    // console.log('ObtainWAN', enabled)
    if (enabled == true) {
      // $(".input-wan" + index).prop('disabled', true);

      $("#wanaddress1" + mac_address).prop('disabled', true);
      $("#wanaddress2" + mac_address).prop('disabled', true);
      $("#wanaddress3" + mac_address).prop('disabled', true);
      $("#wanaddress4" + mac_address).prop('disabled', true);

      $("#wannetmask1" + mac_address).prop('disabled', true);
      $("#wannetmask2" + mac_address).prop('disabled', true);
      $("#wannetmask3" + mac_address).prop('disabled', true);
      $("#wannetmask4" + mac_address).prop('disabled', true);

      $("#wangateway1" + mac_address).prop('disabled', true);
      $("#wangateway2" + mac_address).prop('disabled', true);
      $("#wangateway3" + mac_address).prop('disabled', true);
      $("#wangateway4" + mac_address).prop('disabled', true);

   

    } else {

      //console.log('raveen');

      $("#wanaddress1" + mac_address).prop('disabled', false);
      $("#wanaddress2" + mac_address).prop('disabled', false);
      $("#wanaddress3" + mac_address).prop('disabled', false);
      $("#wanaddress4" + mac_address).prop('disabled', false);

      $("#wannetmask1" + mac_address).prop('disabled', false);
      $("#wannetmask2" + mac_address).prop('disabled', false);
      $("#wannetmask3" + mac_address).prop('disabled', false);
      $("#wannetmask4" + mac_address).prop('disabled', false);

      $("#wangateway1" + mac_address).prop('disabled', false);
      $("#wangateway2" + mac_address).prop('disabled', false);
      $("#wangateway3" + mac_address).prop('disabled', false);
      $("#wangateway4" + mac_address).prop('disabled', false);
    }


  }

  followWAN(devices_id, index, mac_address) {

    //console.log('followWAN....', index)
    //console.log('followWAN....', mac_address)

    var enabled = $("#WanAssignIP" + mac_address).is(":checked");
    // console.log('followWAN', enabled)
    if (enabled == false) {
      $("#wanaddress1" + mac_address).prop('disabled', true);
      $("#wanaddress2" + mac_address).prop('disabled', true);
      $("#wanaddress3" + mac_address).prop('disabled', true);
      $("#wanaddress4" + mac_address).prop('disabled', true);

      $("#wannetmask1" + mac_address).prop('disabled', true);
      $("#wannetmask2" + mac_address).prop('disabled', true);
      $("#wannetmask3" + mac_address).prop('disabled', true);
      $("#wannetmask4" + mac_address).prop('disabled', true);

      $("#wangateway1" + mac_address).prop('disabled', true);
      $("#wangateway2" + mac_address).prop('disabled', true);
      $("#wangateway3" + mac_address).prop('disabled', true);
      $("#wangateway4" + mac_address).prop('disabled', true);


      // $(".input-wan" + index).prop('disabled', false);
    } else {
      $("#wanaddress1" + mac_address).prop('disabled', false);
      $("#wanaddress2" + mac_address).prop('disabled', false);
      $("#wanaddress3" + mac_address).prop('disabled', false);
      $("#wanaddress4" + mac_address).prop('disabled', false);

      $("#wannetmask1" + mac_address).prop('disabled', false);
      $("#wannetmask2" + mac_address).prop('disabled', false);
      $("#wannetmask3" + mac_address).prop('disabled', false);
      $("#wannetmask4" + mac_address).prop('disabled', false);

      $("#wangateway1" + mac_address).prop('disabled', false);
      $("#wangateway2" + mac_address).prop('disabled', false);
      $("#wangateway3" + mac_address).prop('disabled', false);
      $("#wangateway4" + mac_address).prop('disabled', false);
      // $(".input-wan" + index).prop('disabled', true);
    }
  }



  changeStaticRoute(index) {
    var mar = 'staticroutes' + index;
    var checkValue = $("#" + mar).is(":checked");
    //console.log(checkValue);
    if (checkValue == false) {
      $('#staticroutebutton' + index).removeClass('button-display');
      $('#staticroutebutton' + index).addClass('add-testing');
    } else if (checkValue == true) {
      $('#staticroutebutton' + index).removeClass('add-testing');
      $('#staticroutebutton' + index).addClass('button-display');
    }
  }

  allowToggles(index) {
    // //console.log($('#allow_all_ip' + index).checked());
    // var radioValue = $("input[name='getAllowIp'+'']:checked").val();
    // var radioValue = $("#allow_all_ip" +index).prop("checked");
    // alert(index);
    var mar = 'allow_all_ip' + index;
    // alert(mar);
    var radioValue = $("#" + mar).is(":checked");
    if (radioValue == true) {
      $('#ipaddressinput' + index).removeClass('testing');
      $('#ipaddressinput' + index).addClass('add-testing');
      $('#allow_ipaddress' + index).addClass('testing');
      $('#allow_ipaddress' + index).removeClass('add-testing');
      $('#allow_ip_rangeaddress' + index).addClass('testing');
      $('#allow_ip_rangeaddress' + index).removeClass('add-testing');
      // this.ipaddress = true;
    } else {
      $('#ipaddressinput' + index).addClass('testing');
      $('#ipaddressinput' + index).removeClass('add-testing');
      $('#allow_ipaddress' + index).addClass('testing');
      $('#allow_ipaddress' + index).removeClass('add-testing');
      $('#allow_ip_rangeaddress' + index).addClass('testing');
      $('#allow_ip_rangeaddress' + index).removeClass('add-testing');
    }
    //console.log(radioValue);
  }

  allow_ip(index) {
    var mar = 'allow_ip' + index;
    // alert(mar);
    var radioValue = $("#" + mar).is(":checked");
    if (radioValue == true) {
      $('#allow_ipaddress' + index).removeClass('testing');
      $('#allow_ipaddress' + index).addClass('add-testing');
      $('#ipaddressinput' + index).removeClass('add-testing');
      $('#ipaddressinput' + index).addClass('testing');
      $('#allow_ip_rangeaddress' + index).removeClass('add-testing');
      $('#allow_ip_rangeaddress' + index).addClass('testing');
      // this.ipaddress = true;
    } else {
      $('#allow_ipaddress' + index).removeClass('add-testing');
      $('#allow_ipaddress' + index).addClass('testing');
      $('#ipaddressinput' + index).removeClass('add-testing');
      $('#ipaddressinput' + index).addClass('testing');
      $('#allow_ip_rangeaddress' + index).removeClass('add-testing');
      $('#allow_ip_rangeaddress' + index).addClass('testing');
    }
    //console.log(radioValue);
  }

  allow_ip_range(index) {
    var mar = 'allow_ip_range' + index;
    // alert(mar);
    var radioValue = $("#" + mar).is(":checked");
    if (radioValue == true) {
      $('#allow_ip_rangeaddress' + index).removeClass('testing');
      $('#allow_ip_rangeaddress' + index).addClass('add-testing');
      $('#ipaddressinput' + index).addClass('testing');
      $('#ipaddressinput' + index).removeClass('add-testing');
      $('#allow_ipaddress' + index).addClass('testing');
      $('#allow_ipaddress' + index).removeClass('add-testing');
      // this.ipaddress = true;
    } else {
      $('#allow_ip_rangeaddress' + index).addClass('testing');
      $('#allow_ip_rangeaddress' + index).removeClass('add-testing');
      $('#allow_ipaddress' + index).addClass('testing');
      $('#allow_ipaddress' + index).removeClass('add-testing');
      $('#ipaddressinput' + index).addClass('testing');
      $('#ipaddressinput' + index).removeClass('add-testing');
    }
    //console.log(radioValue);
  }

  sip_allow_ip(index) {

    var mar = 'allow_all_ip_sip' + index;
    // alert(mar);
    var radioValue = $("#" + mar).is(":checked");
    //console.log(radioValue);
    if (radioValue == true) {
      $('#sip_allow_ip' + index).removeClass('testing');
      $('#sip_allow_ip' + index).addClass('add-testing');
      $('#sip_allow_ip_range' + index).addClass('testing');
      $('#sip_allow_ip_range' + index).removeClass('add-testing');
    } else {
      $('#sip_allow_ip' + index).addClass('testing');
      $('#sip_allow_ip' + index).removeClass('add-testing');
      $('#sip_allow_ip_range' + index).addClass('testing');
      $('#sip_allow_ip_range' + index).removeClass('add-testing');
    }

  }

  sip_allow_ip_range(index) {
    var mar = 'allow_ip_range_sip' + index;
    // alert(mar);
    var radioValue = $("#" + mar).is(":checked");
    if (radioValue == true) {
      $('#sip_allow_ip_range' + index).removeClass('testing');
      $('#sip_allow_ip_range' + index).addClass('add-testing');
      $('#sip_allow_ip' + index).addClass('testing');
      $('#sip_allow_ip' + index).removeClass('add-testing');

    } else {
      $('#sip_allow_ip_range' + index).addClass('testing');
      $('#sip_allow_ip_range' + index).removeClass('add-testing');
      $('#sip_allow_ip' + index).addClass('testing');
      $('#sip_allow_ip' + index).removeClass('add-testing');
    }
  }

  hello(devices_ids, index) {
    //console.log("welcome");
    $("#" + 'defaultAccordionOne-' + devices_ids).slideToggle();
    this.ipconfiglist(devices_ids);
    setTimeout(() => {
      this.ObtainToggle(devices_ids, index, devices_ids);
      this.followip(devices_ids, index, devices_ids);
      this.ObtainWAN(devices_ids, index, devices_ids);     
      this.followWAN(devices_ids, index, devices_ids);
    }, 18000);
    // $('#defaultAccordionOne-70B3D5043244').slideToggle();
    // this.display_ip_config(devices_ids, index);
    // //console.log("#" + 'defaultAccordionOne-' + '70B3D5043244');
  }


  resellerlicenselist() {

    this.lic_id_array = [];
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'reseller_devices';
    ProfiledetailsReq.reseller_id = this.userId;
    ProfiledetailsReq.dist_id = this.dist_ids;
    ProfiledetailsReq.search_text = this.searchtextval;

    // ProfiledetailsReq.limit = '25';
    // ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      // Swal.close();
      if (response.result_code == '111') {
        this.newList = response.license_list.ticket_options;
        // this.newList = response.data;
        //console.log(this.newList);
        //console.log(this.newList.length);
        this.newList.forEach(values => {
          var mac1 = values.mac_address1;
          var mac2 = values.mac_address2;
          var sno = values.serial_number;
          var dev_model = values.device_model;
          values.cpu = "";
          values.memory = "";
          values.temp = [];
          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          this.lic_id_array.push(testid);
          // this.getdevicestatus();
          this.sendOnload(mac1);

          // this.getnewdevices(sno, mac1, mac2, dev_model);
        });
        //console.log(this.lic_id_array);
        // Swal.close();
      } else if (response.result_code == '222') {
        // Swal.close();
        let messages = 'Failed';
        let texts = 'No devices Found';
        this.errortoastForm(messages, texts);
      }
    }, (error) => {

      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  prevpage() {
    var number = 1;
    var newdefault = +this.default_page - +number;
    //console.log(newdefault);
    if (newdefault > 0) {
      if (this.newList.length != this.total_records) {
        this.paginationval(newdefault);
      }
    }
  }

  nextpage() {
    var number = 1;
    var newdefault = +this.default_page + +number;
    //console.log(newdefault);
    if (newdefault > 0) {
      if (this.newList.length != this.total_records) {
        this.paginationval(newdefault);
      }
    }
  }
  listDataInfo(list_data) {

    // list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
    // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
    // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
    list_data.limit = list_data.limit == undefined ? this.limits : list_data.limit;
    list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
    return list_data;
  }
  // paginationval(selected_page) {

  //   let alldistdata_my = localStorage.getItem("all_dist_data");
  //   let offset_inc=(selected_page-1) * this.limits;
  //   // var select_name = selected_page;


  //   $("#pageval" + selected_page).addClass("active");
  //   $("#pageval" + this.default_page).removeClass("active");
  //   // if (selected_page == 1) {
  //   //   offset_inc = 0;
  //   //   this.default_page = selected_page;
  //   // } else 
  //   if (selected_page > this.default_page) {
  //     // offset_inc = (+this.offset + +this.limits);
  //     offset_inc = (+selected_page * +this.limits - +this.limits);
  //     // alert('increasing:'+offset_inc);
  //     this.default_page = selected_page;
  //   } else {
  //     // offset_inc = (+this.offset - +this.limits);
  //     offset_inc = (+selected_page * +this.limits - +this.limits);
  //     // alert('decreasing:'+offset_inc);
  //     this.default_page = selected_page;
  //   }
  //   // alert(offset_inc);
  //   if (this.newList.length != this.total_records) {
  //     this.offset = offset_inc;
  //     // this.LicenseList();
  //     // this.searchButton();
  //     // this.getdistsearchlist();

  //     //console.log(alldistdata_my);
  //     //console.log(offset_inc);

  //     if ( alldistdata_my == "admin" || alldistdata_my == "all") {
  //       this.LicenseList();
  //     }else{
  //       this.searchButton();
  //     }
  //   }
  // }
  paginationval(selected_page) {
    //console.log(selected_page);

    let alldistdata_my = localStorage.getItem("all_dist_data");
    //console.log(alldistdata_my);
    let offset_inc;

    // var select_name = selected_page;


    $("#pageval" + selected_page).addClass("active");
    $("#pageval" + this.default_page).removeClass("active");
    // if (selected_page == 1) {
    //   offset_inc = 0;
    //   this.default_page = selected_page;
    // } else 
    //console.log(selected_page);
    //console.log(this.default_page);
    if (selected_page > this.default_page) {
      // offset_inc = (+this.offset + +this.limits);
      offset_inc = (+selected_page * +this.limits - +this.limits);
      // alert('increasing:'+offset_inc);
      this.default_page = selected_page;
      //console.log(selected_page);
    } else {
      // offset_inc = (+this.offset - +this.limits);
      offset_inc = (+selected_page * +this.limits - +this.limits);
      // alert('decreasing:'+offset_inc);
      this.default_page = selected_page;
    }
    //console.log(offset_inc);
    //console.log(this.offset);
    //console.log(this.newList.length);
    //console.log(this.total_records);
    // alert(offset_inc);
    if (this.newList.length != this.total_records) {
      this.offset = offset_inc;
      // this.LicenseList();
      // this.searchButton();
      // this.getdistsearchlist();

      //console.log(alldistdata_my);
      //console.log(this.offset);

      if (alldistdata_my === "admin" || alldistdata_my === "all") {
        this.LicenseList();
      } else {
        this.searchButton();
      }
    }
  }

  // LicenseList(data) {
  //   var list_data= this.listDataInfo(data);
  //   this.lic_id_array = [];
  //   const ProfiledetailsReq: any = new Object();
  //   const profileList: any = ({
  //     'access_token': this.accesstoken,
  //     'api_type': 'web',
  //     'operation': 'curlData',
  //     'moduleType': 'license',
  //     'element_data': ProfiledetailsReq,

  //   });

  //   ProfiledetailsReq.action = 'license_list';
  //   ProfiledetailsReq.customer_id = this.userId;  
  //   ProfiledetailsReq.search_text = this.searchtextval;

  //   if (this.newList.length == 0) {
  //     this.offset = 0;
  //   }

  //   ProfiledetailsReq.limit = list_data.limit;
  //   ProfiledetailsReq.offset = list_data.offset;
  //   this.apiservice.newsendServer(profileList).then((response: any) => {

  //     if (response.status == 'true') {
  //       this.newList = response.license_list;
  //       this.total_records = response.tot_records;
  //       this.offset = response.offset;
  //       this.pages_num = [];
  //       var round_val = Math.ceil(this.total_records / 20);
  //       this.paginationData = this.apiservice.pagination({'offset':response.offset, 'total':response.tot_records, 'page_limit' :this.limits });
  //       for (var i = 0; i < round_val; i++) {
  //         if(i > 9){
  //           break;
  //         } else {
  //           this.pages_num.push(i + 1);
  //         }
  //       }
  //       if(this.offset == 0){
  //         $('#pageval1').addClass('active');
  //       $('#pageval'+this.default_page).removeClass('active');
  //       }

  //       //console.log(this.pages_num);
  //       //console.log(this.newList);
  //       //console.log(this.newList.length);
  //       this.newList.forEach(values => {
  //         var mac1 = values.mac_address1;
  //         var mac2 = values.mac_address2;
  //         var sno = values.serial_number;
  //         var dev_model = values.device_model;
  //         values.cpu = "";
  //         values.memory = "";
  //         values.temp = [];
  //       //console.log(dev_model);
  //       //console.log(mac1);

  //         var wanip = mac1.toLowerCase();
  //         var id = btoa('WAN=' + wanip);
  //         var testid = btoa(id);

  //         this.sendOnload(mac1);

  //       });
  //       //console.log(this.lic_id_array);

  //       $('#lic_id_array').val(this.lic_id_array);

  //     } else if (response.status == 'false') {

  //     }
  //   }, (error) => {

  //     //console.log(error);

  //   });
  // }

  // notesid(ids, notes) {
  //   this.existNotes = '';
  //   this.notesidvalue = ids;
  //   //console.log(ids);
  //   // this.existNotes = notes;
  //   this.notesdata.patchValue({
  //     notesvalues: notes,
  //     // formControlName2: myValue2 (can be omitted)
  //   });

  //   // this.existNotes = $('#notes-text'+ids).text();
  //   // alert(this.existNotes);

  // } 

  LicenseList() {
    // alert(1)
    this.lic_id_array = [];
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'license_list';
    ProfiledetailsReq.customer_id = this.userId;
    ProfiledetailsReq.search_text = this.searchtextval;
    //console.log(this.newList.length);
    if (this.newList.length == 0) {
      this.offset = 0;
    }
    //console.log(this.offset);
    ProfiledetailsReq.limit = this.limits;
    ProfiledetailsReq.offset = this.offset;
    this.apiservice.newsendServer(profileList).then((response: any) => {

      if (response.status == 'true') {
        this.newList = response.license_list;
        //console.log(this.newList.length);
        //console.log(this.total_records);
        this.total_records = response.tot_records;

        this.pages_num = [];
        var round_val = Math.ceil(this.total_records / 20);
        this.paginationData = this.apiservice.pagination({ 'offset': this.offset, 'total': response.tot_records, 'page_limit': this.limits });
        this.offset = response.offset;
        for (var i = 0; i < round_val; i++) {
          if (i > 9) {
            break;
          } else {
            this.pages_num.push(i + 1);
          }
        }
        if (this.offset == 0) {
          $('#pageval1').addClass('active');
          $('#pageval' + this.default_page).removeClass('active');
        }

        //console.log(this.pages_num);
        //console.log(this.newList);
        //console.log(this.newList.length);
        this.newList.forEach(values => {
          var mac1 = values.mac_address1;
          var mac2 = values.mac_address2;
          var sno = values.serial_number;
          var dev_model = values.device_model;
          values.cpu = "";
          values.memory = "";
          values.temp = [];
          //console.log(dev_model);
          //console.log(mac1);

          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);

          this.sendOnload(mac1);

        });
        //console.log(this.lic_id_array);

        $('#lic_id_array').val(this.lic_id_array);

      } else if (response.status == 'false') {

      }
    }, (error) => {

      console.log(error);

    });
  }

  notesid(ids, notes) {
    this.existNotes = '';
    this.notesidvalue = ids;
    //console.log(ids);
    // this.existNotes = notes;
    this.notesdata.patchValue({
      notesvalues: notes,
      // formControlName2: myValue2 (can be omitted)
    });

    // this.existNotes = $('#notes-text'+ids).text();
    // alert(this.existNotes);

  }

  saveNotes() {

    $('#addNotesModal').modal('hide');
    //console.log(this.notesdata.value.notesvalues);
    // alert(this.notesdata.value.notesvalues);
    // return false;
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller","element_data":{"action":"add_notes","sno":"N3X2L20201012","mac1":"70B3D50436C3","mac2":"70B3D50436C2","notes":"test"}}
    var sno = $("#sno" + this.notesidvalue).text();
    var mac1 = $("#mac1" + this.notesidvalue).text();
    var mac2 = $("#mac2" + this.notesidvalue).text();
    const notesReq: any = new Object();
    const savenoteslist: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': notesReq,
    });

    notesReq.action = 'add_notes';
    notesReq.sno = sno;
    notesReq.mac1 = mac1;
    notesReq.mac2 = mac2;
    notesReq.notes = this.notesdata.value.notesvalues;
    this.apiservice.newsendServer(savenoteslist).then((response: any) => {
      //console.log(response);
      this.loginDisplayDevices();
      if (response.result_code == '1') {
        this.toastForm('Successfully', 'Notes Updated');
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }



  sendOnload(wanmac) {


    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);

    var ipconfig = '{"action":"getIPConfigData","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(ipconfig);
    // //console.log(ipconfig);
    // var devicesupdate = '{"action":"getAppVersionUpdate","uid":"' + id + '"}';
    //  this.websocket.send(devicesupdate);

    // var sipconnectList = '{"action":"getSipConnectData","uid":"'+ id+'"}';
    // this.websocket.send(sipconnectList);

    // var settingslist = '{"action":"check_usb_device","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    // this.websocket.send(settingslist);
  }

  selectOneValue(mac1, lic_id, events) {
    alert("hgghggh");
    //console.log('testing selection');
    var checkValue = $("#flexCheckDefault" + mac1).is(":checked");
    if (checkValue == true) {
      localStorage.setItem('checks', 'true');
    } else {
      localStorage.setItem('checks', 'false');
    }
    localStorage.setItem('checksid', mac1);
    //console.log(checkValue);
    if (checkValue == true) {
      // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller","element_data":{"action":"filter_license","license_id":"1823"}}
      this.lic_id_array = [];
      const ProfiledetailsReq: any = new Object();
      const profileList: any = ({
        'access_token': this.accesstoken,
        'api_type': 'web',
        'operation': 'curlData',
        'moduleType': 'reseller',
        'element_data': ProfiledetailsReq,
      });

      ProfiledetailsReq.action = 'filter_license';
      ProfiledetailsReq.license_id = lic_id;

      this.apiservice.newsendServer(profileList).then((response: any) => {
        if (response.status == 'true') {
          this.newList = response.license_list;
          var check_id = localStorage.getItem('checksid');
          var newchecks = localStorage.getItem('checks');

          //console.log(this.newList);
          //console.log(this.newList.length);
          this.newList.forEach(values => {
            var mac1 = values.mac_address1;
            var mac2 = values.mac_address2;
            var sno = values.serial_number;
            var dev_model = values.device_model;


            var wanip = mac1.toLowerCase();
            var id = btoa('WAN=' + wanip);
            var testid = btoa(id);
            this.lic_id_array.push(testid);
            // this.getdevicestatus();
            this.sendOnload(mac1);

            //  if(dev_model == "NX16-SBC"){
            //    var mac1 = values.mac_address1;
            //    var sno = values.serial_number;
            // alert(mac1)

            //  }
          });
          setTimeout(() => {


            if (newchecks == 'true') {
              // this.checkedvalues = true;
              var newones = '#flexCheckDefault' + check_id;
              //console.log(newones);
              $(newones).prop('checked', true);

              // //console.log( $("#flexCheckDefault"+check_id).prop('checked',checks));
            } else if (newchecks == 'false') {
              var newones = '#flexCheckDefault' + check_id;
              //console.log(newones);
              $(newones).prop('checked', false);

            }
          }, 3000);
          //console.log(this.lic_id_array);
        } else if (response.status == 'false') {
          // this.showfalsestate = true;
        }
      }, (error) => {

        console.log(error);
        // this.apiservice.dismiss();
      });
    } else if (checkValue == false) {
      this.loginDisplayDevices();
    }

  }
  ipconfiglist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var ipconfig = '{"action":"getIPConfigData","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(ipconfig);

  }

  firewalllist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var firewall = '{"action":"getFirewallData","uid":"' + id + '"}';
    this.websocket.send(firewall);
  }
  hideNetwork() {
    this.isShowLan = true;
  }
  //123456
  settinglist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var settingslist = '{"action":"check_usb_device","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(settingslist);
  }
  nx16settinglist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var nx16settingslist = '{"action":"check_usb_device1","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(nx16settingslist);
  }
  aiolist(wanmac) {
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var aiolist = '{"action":"get_analoginfo","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(aiolist);
  }
  showNetworkDetails(wanmac) {
    //console.log("inside");
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var Networklist = '{"action":"getNetworkLanSettings","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(Networklist);
    this.isShowLan = !this.isShowLan;
  }
  statuslist(wanmac) {
    //console.log("inside");
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var Analoglist = '{"action":"get_analoginfo","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(Analoglist);
  }
  showSipEndpoitDetailsGetAll(wanmac) {
    //console.log("inside");
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var Analoglist = '{"action":"getSipEndpointGetAll","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(Analoglist);
  }


  updatelist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    id = btoa(id);
    var devicesupdate = '{"action":"getAppVersionUpdate","uid":"' + id + '"}';
    this.websocket.send(devicesupdate);

  }



  cpu_performance(wanmac) {

    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 4000
    });

    //console.log(this.newList);
    //console.log(wanmac);
    newListjs = this.newList;

    //console.log(newListjs);
    this.newList.forEach(values => {
      var mac1 = values.mac_address1;
      var mac2 = values.mac_address2;
      var sno = values.serial_number;
      var dev_model = values.device_model;
      //console.log(mac1);
      //console.log(mac2);
      //console.log(dev_model);
      // return false
      var wanip = mac1.toLowerCase();
      var id = btoa('WAN=' + wanip);
      var testid = btoa(id);
      this.lic_id_array.push(testid);
      // this.getdevicestatus();
      this.sendOnload(mac1);

    });

    $('#' + wanmac).innerHTML = 'new text';
    var wanip = wanmac.toLowerCase();
    //console.log(wanip);
    var id = btoa('WAN=' + wanip);


    id = btoa(id);

    this.hostname = 'localhost';
    var getDeviceMemory_data = '{"action":"getDeviceMemory","hostname":"' + this.hostname + '","uid":"' + id + '"}';

    var getDeviceCpudata = '{"action":"getDeviceCpu","hostname":"' + this.hostname + '","uid":"' + id + '"}';

    var getDeviceTempdata = '{"action":"getDeviceTemp","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(getDeviceMemory_data);
    this.websocket.send(getDeviceCpudata);
    this.websocket.send(getDeviceTempdata);

  }



  sipconnectlist(wanmac) {
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var sipconnectList = '{"action":"getSipConnectData","uid":"' + id + '"}';
    this.websocket.send(sipconnectList);
  }

  initSocket() {

    // this.Reconnect()
    // this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4021");
    this.websocket = new WebSocket("wss://nxsocket.mconnectapps.com:4000");
    // this.websocket = new WebSocket("wss://nxhandler.call4tel.com/v1/graphql");
    var self = this;
    this.websocket.onopen = function (event) {
      // $('#sendonload').click();
      //console.log('agent socket connected');
    }

    this.websocket.onmessage = function (event) {
      var self = this;
      //console.log(event.data);
      var mdata = JSON.parse(event.data)
      //console.log('123')
      // {"wan": ["dhcp"], "lan": ["static", "192.168.101.1", "255.255.255.0", "192.168.101.1"], "uid": "VjBGT1BUY3dZak5rTlRBME16STBOQT09", "dns": ["nameserver", "8.8.8.8", "nameserver", "8.8.4.4"], "current_version": "1.237"}
      var result_message = JSON.parse(event.data);
      // this.hardware_id = localStorage.getItem('hardware_id');
      //console.log("result message:::", result_message);
      // //console.log(self.newList)
      //console.log(newListjs);

      self.newList = newListjs;
      //console.log(self.newList);
      //console.log(event.data)
      if (result_message.action == 'response-getNetworkLanSettings') {
        //console.log("INSIDE getNetworkLanSettings", result_message);
        this.openNetDet = true
        this.show//console = false
        this.showLaunchPad = false
        var convert = require('xml-js');
        var xml = result_message.data;
        var result1 = convert.xml2json(xml, { compact: true, spaces: 4 });
        result1 = JSON.parse(result1);
        //console.log(result1)
        const lanSett = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkGetResponse']['get']['lan'];
        //console.log("lanset:", lanSett);
        this.aioLantype = lanSett['type']['_text'];
        this.aioLanmac = lanSett['mac']['_text'];
        this.aioLanaddress = lanSett['ipaddr']['_text'];
        this.aioLannetmask = lanSett['netmask']['_text'];
        this.aioLangateway = lanSett['gateway']['_text'];
        //console.log("THIS ONE", this.aioLantype);
      }
      if (result_message.action == 'response-rebootSystem') {
      }
      if (result_message.action == 'response-get_analoginfo') {
        //console.log("INSIDE get_analoginfo");
        this.mainPortsDetails = result_message;
        //console.log(result_message);
        this.mainPortsDetails = JSON.parse(JSON.stringify(this.mainPortsDetails));
        //console.log(this.mainPortsDetails);

      }
      if (result_message.action == 'response-getLaunchPadList') {

      }
      if (result_message.action == 'response-saveLaunchPadList') {

      }
      if (result_message.action == 'response-saveLanSettings') {

      }
      if (result_message.action == 'response-getNXIPAddress') {

      }
      if (result_message.action == 'response-getSystemStatus') {

      }
      if (result_message.action == 'response-getSipEndpointGetAll') {
        //console.log("INSIDE  getSipEndpointgetall")
        var convert = require('xml-js');
        var xml = result_message.data;
        var result1 = convert.xml2json(xml, { compact: true, spaces: 4 });
        result1 = JSON.parse(result1);
        //console.log(result1);
        this.listSipEndpoitDetails = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointGetAllResponse']['get']['sips']['item'];
        //console.log(this.listSipEndpoitDetails);
        if (this.listSipEndpoitDetails.length) {
          this.showSipEndpoitDetails = false
        } else {
          this.showSipEndpoitDetails = true
        }
      }
      if (result_message.action == 'response-getsipEndpointsDel') {

      }
      if (result_message.action == 'response-getRoutingRulesGetAll') {

      }
      if (result_message.action == 'response-getRoutingRulesDel') {

      }
      if (result_message.action == 'response-getRoutingGroupGetAll') {

      }
      if (result_message.action == 'response-getRoutingGroupDel') {

      }
      if (result_message.action == 'response-saveRoutingGroupSave') {

      }
      if (result_message.action == 'response-saveCallRouteData') {

      }
      if (result_message.action == 'response-getsingleRoutingData') {

      }
      if (result_message.action == 'response-saveUpdatedCallRouteData') {

      }
      if (result_message.action == 'response-saveSpiEndpointsData') {

      }
      if (result_message.action == 'response-getDeviceTemp') {
        // //console.log(result_message)
        // var recievd_msg = JSON.parse(result_message)
        // var id = atob(recievd_msg.uid.replace(/[^\x20-\x7E]/g, ''));
        // id = atob(id.replace(/[^\x20-\x7E]/g, ''));
        // var uuid = id.split('=')[1];
        // var newuuid = uuid.toUpperCase();
        // //console.log(newuuid);

        // var temperature = atob(recievd_msg.temp.replace(/[^\x20-\x7E]/g, ''));
        // //console.log(temperature);
        // var temperatur = temperature.split("C")
        // //console.log(temperatur)
        // var temps = [];
        // for (var i = 0; i < temperatur.length; i++) {
        //   temps.push(temperatur[i].split(/_/g));

        // }
        // //console.log(temps)
        // var tempss = [];
        // for (var i = 0; i < temps.length; i++) {
        //   var tempval = temps[i][temps[i].length - 1];
        //   tempss.push(tempval);

        // }
        // for (var i = 0; i < tempss.length; i++) {
        //   tempss[i] = tempss[i].replace("Â°", "°C")
        // }
        // //console.log(tempss)
        // for (var i = 0; i < self.newList.length; i++) {
        //   if (self.newList[i].mac_address1 == newuuid) {

        //     self.newList[i].temp = tempss;
        //   } else {
        //     self.newList[i].temp = '';

        //   }
        // }
        // //console.log(self.newList)
        var recievd_msg = JSON.parse(result_message)
        var id = atob(recievd_msg.uid);
        id = atob(id);
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();
        //console.log(newuuid);

        var temperature = atob(recievd_msg.temp);
        //console.log(temperature);
        var temperatur = temperature.split("C")
        //console.log(temperatur)
        var temps = [];
        for (var i = 0; i < temperatur.length; i++) {
          temps.push(temperatur[i].split(/_/g));

        }
        //console.log(temps)
        var tempss = [];
        for (var i = 0; i < temps.length; i++) {
          var tempval = temps[i][temps[i].length - 1];
          tempss.push(tempval);

        }
        for (var i = 0; i < tempss.length; i++) {
          tempss[i] = tempss[i].replace("Â°", "°C")
        }
        //console.log(tempss)
        for (var i = 0; i < self.newList.length; i++) {
          if (self.newList[i].mac_address1 == newuuid) {

            self.newList[i].temp = tempss;
          } else {
            self.newList[i].temp = '';

          }
        }
        //console.log(self.newList)

      }

      if (result_message.action == 'response-getDeviceMemory') {
        // var self=this;
        var id = atob(result_message.uid);
        id = atob(id);

        // self.getfun();
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();
        //console.log(this.newList)
        //console.log(self.newList)
        for (var i = 0; i < self.newList.length; i++) {
          if (self.newList[i].mac_address1 == newuuid) {

            self.newList[i].memory = result_message.memory;
          }
        }
        //console.log(self.newList)
      }
      if (result_message.action == 'response-getDeviceCpu') {
        // //console.log(self.newList)

        var id = atob(result_message.uid);
        id = atob(id);
        var self = this;
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();

        for (var i = 0; i < self.newList.length; i++) {
          if (self.newList[i].mac_address1 == newuuid) {

            self.newList[i].cpu = result_message.cpu;
          }
        }


      }
      if (result_message.action == 'cloudConnectionEnabled') {
        var id = atob(result_message.uid);
        id = atob(id);
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();
        $("#activation" + newuuid).prop('checked', true);

      }

      if (result_message.action == 'cloudConnectionDisabled') {
        var id = atob(result_message.uid);
        id = atob(id);
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();
        $("#activation" + newuuid).prop('checked', false);

      }


      if (result_message.action == 'response-sbc_to_3cx') {
        var cnofirm = '';

        self.newList.forEach(element => {

          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cnofirm = 'true';
          }

        });

        if (cnofirm == 'true') {
          Swal.close();
          if (result_message.status == 1) {
            Swal.fire({
              title: 'Please Wait',
              html: '<b></b> minutes, uninstalled SBC and get reboot',
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 300000,
              onOpen: () => {
                var minute = 4;
                var sec = 59;
                var intervalId = setInterval(function () {
                  // document.getElementById("timer").innerHTML = minute + " : " + sec;
                  //console.log(minute + " : " + sec);
                  var timers = minute + " : " + sec;
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                      b.textContent = timers
                    }
                    sec--;
                    if (sec == 0o0) {
                      minute--;
                      sec = 60;
                      if (minute == -1) {
                        // minute = 0;
                        clearInterval(intervalId);
                      }
                    }
                  }
                }, 1000);
                Swal.showLoading();
              }
            }).then(
              () => { },
              (dismiss) => {
                if (dismiss === 'timer') {
                  //console.log('closed by timer!!!!');
                  Swal.fire({
                    title: 'Devices Rebooted!',
                    // type: 'success',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                  })
                  var id = atob(result_message.uid);
                  id = atob(id);
                  var uuid = id.split('=')[1];
                  var newuuid = uuid.toUpperCase();
                  self.ipconfiglist(newuuid);
                }
              }
            );
          }
        }
      }


      if (result_message.action == 'response-update_ip_config') {

        var cofirm = '';


        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cofirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });

        if (cofirm == 'true') {
          Swal.close();
          if (result_message.status == 1) {
            Swal.fire({
              title: 'Please Wait',
              html: '<b></b> minutes, IP updated and device will get reboot',
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 180000,
              onOpen: () => {
                var minute = 2;
                var sec = 59;
                var intervalId = setInterval(function () {
                  // document.getElementById("timer").innerHTML = minute + " : " + sec;
                  //console.log(minute + " : " + sec);
                  var timers = minute + " : " + sec;
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                      b.textContent = timers
                    }
                    sec--;
                    if (sec == 0o0) {
                      minute--;
                      sec = 60;
                      if (minute == -1) {
                        // minute = 0;
                        clearInterval(intervalId);
                      }
                    }
                  }
                }, 1000);
                Swal.showLoading();
              }
            }).then(
              () => { },
              (dismiss) => {
                if (dismiss === 'timer') {
                  //console.log('closed by timer!!!!');
                  Swal.fire({
                    title: 'Devices Rebooted!',
                    // type: 'success',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                  })
                  var id = atob(result_message.uid);
                  id = atob(id);
                  var uuid = id.split('=')[1];
                  var newuuid = uuid.toUpperCase();
                  self.ipconfiglist(newuuid);
                }
              }
            );
          } else {
            // this.errortoastForm('Failed', 'IP Config Failed');
            Swal.fire({
              // title: 'Updated',
              //   position: 'top-end',
              title: 'Failed',
              text: 'IP Config Failed',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            });
          }
        }
      }

      if (result_message.action == 'response-checkOnlineStatus') {
        // var id = atob(result_message.uid);
        // id = atob(id);
        // var uuid = id.split('=')[1];
        // var newuuid = uuid.toUpperCase();

      }

      if (result_message.action == 'cloudConnectionEnabled') {
        var id = atob(result_message.uid);
        id = atob(id);
        var uuid = id.split('=')[1];
        var newuuid = uuid.toUpperCase();


      }

      if (result_message.action == 'response-commands_3cx') {
        var cofirm = '';
        var self = this;

        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cofirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });


        if (cofirm == 'true') {
          Swal.fire({
            title: 'Please Wait',
            html: '<b></b> minutes, please wait devices is Rebooting..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 180000,
            onOpen: () => {
              var minute = 2;
              var sec = 59;
              var intervalId = setInterval(function () {
                // document.getElementById("timer").innerHTML = minute + " : " + sec;
                //console.log(minute + " : " + sec);
                var timers = minute + " : " + sec;
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = timers
                  }
                  sec--;
                  if (sec == 0o0) {
                    minute--;
                    sec = 60;
                    if (minute == -1) {
                      // minute = 0;
                      clearInterval(intervalId);
                    }
                  }
                }
              }, 1000);
              Swal.showLoading();
            }
          }).then(
            () => { },
            (dismiss) => {
              if (dismiss === 'timer') {
                //console.log('closed by timer!!!!');
                Swal.fire({
                  title: 'Devices Rebooted!',
                  // type: 'success',
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false
                })
              }
            }
          );

        }

      }


      if (result_message.action == 'getMySerialNum') {
        var confirm = '';
        // self.lic_id_array.forEach(element => {
        //   //console.log(element);
        //   if (result_message.uid == element) {
        //     confirm = 'true';
        //   }
        // });

        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            confirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });
        if (confirm == 'true') {

          var id = atob(result_message.uid);
          id = atob(id);
          var uuid = id.split('=')[1];
          var newuuid = uuid.toUpperCase();
          var serialno = $('#sno' + newuuid).text();

          var switching = '{action: "response-mySerialNumber",uid: "' + result_message.uid + '",sno: "' + serialno + '"}';
          self.websocket.send(switching);
          //console.log('sended successfully');
        }
      }

      if (result_message.action == 'response-update_sip_connect') {
        Swal.close();
        var confirm = '';
        // self.lic_id_array.forEach(element => {
        //   //console.log(element);
        //   if (result_message.uid == element) {
        //     confirm = 'true';
        //   }
        // });

        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            confirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });

        if (confirm == 'true') {
          if (result_message.status == 1) {
            Swal.fire({
              // title: 'Updated',
              //   position: 'top-end',
              title: 'Sucessfully',
              text: 'Sip Connect Updated',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });


            var id = atob(result_message.uid);
            id = atob(id);
            var uuid = id.split('=')[1];
            var newuuid = uuid.toUpperCase();
            self.sipconnectlist(newuuid);
            // this.toastForm('Sucessfully', 'Sip Connect Updated');
          } else {
            // this.errortoastForm('Failed', 'Sip Connect Failed');
            Swal.fire({
              // title: 'Updated',
              //   position: 'top-end',
              title: 'Failed',
              text: 'Sip Connect Failed',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            });
          }
        }
      }


      if (result_message.action == "response-3cxsbc_config") {
        var cnofirm = '';
        // self.lic_id_array.forEach(element => {
        //   //console.log(element);
        //   if (result_message.uid == element) {
        //     cnofirm = 'true';
        //   }
        // });
        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cnofirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });

        if (cnofirm == 'true') {
          Swal.close();
          if (result_message.status == 1) {
            Swal.fire({
              title: 'Please Wait',
              html: '<b></b> minutes, device configure as  SBC & get Reboot',
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 180000,
              onOpen: () => {
                var minute = 2;
                var sec = 59;
                var intervalId = setInterval(function () {
                  // document.getElementById("timer").innerHTML = minute + " : " + sec;
                  //console.log(minute + " : " + sec);
                  var timers = minute + " : " + sec;
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                      b.textContent = timers
                    }
                    sec--;
                    if (sec == 0o0) {
                      minute--;
                      sec = 60;
                      if (minute == -1) {
                        // minute = 0;
                        clearInterval(intervalId);
                      }
                    }
                  }
                }, 1000);
                Swal.showLoading();
              }
            }).then(
              () => { },
              (dismiss) => {
                if (dismiss === 'timer') {
                  //console.log('closed by timer!!!!');
                  Swal.fire({
                    title: 'Devices Rebooted!',
                    // type: 'success',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                  })
                }
              }
            );
          } else {
            // this.errortoastForm('Failed', 'IP Config Failed');
            Swal.fire({
              // title: 'Updated',
              //   position: 'top-end',
              title: 'Failed',
              text: 'IP Config Failed',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            });
          }
        }
      }

      if (result_message.action == "response-allowNewPortChange") {
        // this.toastForm('Sucessfully', 'Firewall Updated');
        var array = $('#lic_id_array').val().split(',');
        this.lic_id_array = array;
        //console.log(this.lic_id_array);
        var cnofirm = '';

        self.newList.forEach(element => {

          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cnofirm = 'true';
          }

        });



        if (cnofirm == 'true') {
          Swal.close();
          Swal.fire({
            // title: 'Updated',
            //   position: 'top-end',
            title: 'Sucessfully',
            text: 'Firewall Updated',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          var id = atob(result_message.uid);
          id = atob(id);

          var uuid = id.split('=')[1];
          var newuuid = uuid.toUpperCase();
          $('#firewalllistRedirect').val(newuuid);
          $('#firewalllistRedirect').click()

        }
      }

      if (result_message.action == "response-check_usb_device") {
        $('#settingslist').val(event.data);
        $('#settingslist').click();
      }

      if (result_message.action == "response-check_usb_device1") {
        $('#nx16settingslist').val(event.data);
        $('#nx16settingslist').click();
      }


      if (result_message.action == "response-getAppVersionUpdate") {
        $('#newupdates').val(event.data);
        $('#newupdates').click();
      }
      if (result_message.action == "response-VersionUpdate") {
        // Swal.close();

        Swal.fire({
          // title: 'Updated',
          //   position: 'top-end',
          title: 'Sucessfully',
          text: 'New version Updated',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
      }
      if (result_message.action == "response-getSipConnectData") {
        $('#sipconnectData').val(event.data);
        $('#sipconnectData').click();
      }

      if (result_message.action == "response-removePortChange") {
        var array = $('#lic_id_array').val().split(',');
        this.lic_id_array = array;
        var cnirm = '';
        // self.lic_id_array.forEach(element => {
        //   //console.log(element);
        //   if (result_message.uid == element) {
        //     cnirm = 'true';
        //   }
        // });

        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;


          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cnirm = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });

        if (cnirm == 'true') {
          Swal.fire({
            // title: 'Updated',
            //   position: 'top-end',
            title: 'Sucessfully',
            text: 'Remove Port',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          var self = this;
          var id = atob(result_message.uid);
          id = atob(id);
          var uuid = id.split('=')[1];
          var newuuid = uuid.toUpperCase();
          $('#firewalllistRedirect').val(newuuid);
          $('#firewalllistRedirect').click()
        }
      }

      if (result_message.action == "response-removeSipRouteCmd") {
        Swal.close()
        var cnfim = '';
        // self.lic_id_array.forEach(element => {
        //   //console.log(element);
        //   if (result_message.uid == element) {
        //     cnfim = 'true';
        //   }
        // });

        self.newList.forEach(element => {
          // //console.log(element.serial_number);
          // //console.log(element.serial_number.join(","));
          var mac1 = element.mac_address1;
          var mac2 = element.mac_address2;
          var sno = element.serial_number;
          var dev_model = element.device_model;

          //console.log(dev_model);
          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          // self.lic_id_array.push(testid);
          if (result_message.uid == testid) {
            cnfim = 'true';
          }

          // testing.push(element.serial_number);
          // //console.log(testing);
        });

        if (cnfim == 'true') {
          Swal.fire({
            // title: 'Updated',
            //   position: 'top-end',
            title: 'Sucessfully',
            text: 'Remove Port',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          var id = atob(result_message.uid);
          var self = this;
          id = atob(id);
          var uuid = id.split('=')[1];
          var newuuid = uuid.toUpperCase();
          self.sipconnectlist(newuuid);
        }



      }


      if (result_message.mode != undefined && result_message.mode != null) {
        $('#firwalldatas').val(event.data);
        $('#firwalldatas').click();
      } else if (result_message.action == 'response-getIPConfigData') {
        $('#sendonload').val(event.data);
        $('#sendonload').click();
      } else if (result_message.action == 'response-getFirewallData') {
        $('#sendonload').val(event.data);
        $('#sendonload').click();
      }


    }
    this.websocket.onerror = function (event) {
      // Swal.close();

      console.log('error');
    }

    this.websocket.onclose = function (event) {
      // Swal.close();
      var self = this;
      setTimeout(self.Reconnect, 1000);
      console.log('close');
    }
  }

  getfun() {
    //console.log(this.newList)
  }
  Reconnect() {
    // //console.log("jdahsjdhasjdhjashdjkhas")
    var websocket = new WebSocket("wss://myscoket.mconnectapps.com:4000/");
  }

  changesActivated(mac1, events) {
    this.activatedids = mac1;
    $('#openModal-persmission').modal('show');
    //  this.confirmdeactivate(mac1);
  }

  confirmdeactivate() {
    var mac1 = this.activatedids;
    // {"action":"disconnectSocketDevice","hostname":"localhost", "uid":"'+id+'"};
    $('#openModal-persmission').modal('hide');
    var mar = 'activation' + mac1;
    var checkValue = $("#" + mar).is(":checked");
    //console.log(checkValue);
    if (checkValue == false) {
      var wanip = mac1.toLowerCase();
      var id = btoa('WAN=' + wanip);

      id = btoa(id);
      var deactivated = '{"action":"disconnectSocketDevice","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
      this.websocket.send(deactivated);
    }
  }

  canceldeactivation() {
    $('#openModal-persmission').modal('hide');
    var mac1 = this.activatedids;
    $("#activation" + mac1).prop('checked', true);
  }

  switchTo3cx() {

    $('#openModal-abouts').modal('hide');

    Swal.fire({
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var wanip = this.macvalue1.toLowerCase();
    var id = btoa('WAN=' + wanip);

    id = btoa(id);
    //console.log(id)
    var switching = '{"action":"sbc_to_3cx","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(switching);
  }

  getsettingslist() {
    let settingList = $('#settingslist').val();
    let ssData = JSON.parse(settingList);
    //console.log(ssData);
    var id = atob(ssData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(ssData);

    var heading = '';
    var versioncontent = '';
    var versionnum = '';
    var listversion = '';
    if (ssData.app_details == '3cx') {
      versionnum = ssData.version_info;
    }
    var devicetype = $('#devices_models' + newuuid).text();
    if (ssData.app_details == 'sbc') {
      $("#use_device_as_sbc" + newuuid).prop('checked', true);
    } else if (ssData.app_details == '3cx') {
      $("#use_device_as_3cx" + newuuid).prop('checked', true);
    }

    if (ssData.app_details == 'sbc') {
      heading = '3CX SBC Information';
      if (ssData.has_edit == 'true' && ssData.app_details == 'sbc') {
        versioncontent = '3CX SBC:installed on ';
      } else if (ssData.has_edit == 'false' && ssData.app_details == 'sbc') {
        versioncontent = '3CX SBC:Not Installed on ';
      }
    } else if (ssData.app_details == '3cx') {
      heading = '3CX Information';
      versioncontent = '3CX installed on ';
    }
    if (ssData.app_details == 'sbc') {
      listversion = '<div class="row"><div class="col-lg-12 col-12"><div class="row"><div class="col-lg-12 col-12"><h4 class="text-center mb-4"><b>' + heading + '</b></h4></div></div><h6 class="text-center">' + versioncontent + devicetype + '</h6></div></div>';
    } else if (ssData.app_details == '3cx') {
      listversion = '<div class="row"><div class="col-lg-12 col-12"><div class="row"><div class="col-lg-12 col-12"><h4 class="text-center mb-4"><b>' + heading + '</b></h4></div></div><h6 class="text-center">' + versioncontent + devicetype + '<b class="blue-clr"> V :-' + versionnum + '</b></h6></div></div>';
    }
    $("#setting_version" + newuuid).html(listversion);
  }

  nx16getsettingslist() {

    let nx16settingslist = $('#nx16settingslist').val();
    //console.log(nx16settingslist);
    //console.log('sda');
    let ssData = JSON.parse(nx16settingslist);

    this.sbc_pass_edit = ssData.sbc_pass;
    this.web_url_edit = ssData.web_url;
    this.pbx_sip_port_edit = ssData.pbx_sip_port;
    this.auth_key_edit = ssData.auth_key;
    this.pbx_tunnel_port_edit = ssData.pbx_tunnel_port;


    //console.log(this.sbc_pass_edit);
    this.isSBC = ssData.app_details;
    var id = atob(ssData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(ssData);

    var heading = '';
    var versioncontent = '';
    var versionnum = '';
    var listversion = '';

    var devicetype = $('#devices_models' + newuuid).text();


    if (ssData.app_details == '3CX SBC Installed') {
      heading = '3CX SBC Information';
      if (ssData.has_edit == 'true' && ssData.app_details == '3CX SBC Installed') {
        versioncontent = '3CX SBC:installed on ';
      } else if (ssData.has_edit == 'false' && ssData.app_details == '3CX SBC Installed') {
        versioncontent = '3CX SBC:Not Installed on ';
      }
    }

    if (ssData.app_details == '3CX SBC Installed') {
      listversion = '<div class="row"><div class="col-lg-12 col-12"><div class="row"><div class="col-lg-12 col-12"><h4 class="text-center mb-4"><b>' + heading + '</b></h4></div></div><h6 class="text-center">' + versioncontent + devicetype + '</h6></div></div>';
    }
    $("#setting_version" + newuuid).html(listversion);


  }

  sipconnectData() {
    let sipData = $('#sipconnectData').val();
    //console.log(sipData);
    let sData = JSON.parse(sipData);
    var id = atob(sData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(newuuid);
    var finalData = sData.data.replace(/\\/g, "");
    var newcomments = JSON.parse(finalData);

    $("#allow_ip_range_sip" + newuuid).prop('checked', false);
    $("#network_type_wan" + newuuid).prop('checked', false);
    var listcontent = '';
    //console.log(newcomments);
    newcomments.forEach((item) => {
      var d = "'" + newuuid + "'";
      var route = "'" + item.route_id + "'";
      listcontent += '<li onclick="removeports(' + d + ',' + route + ')"><span class="box-content">' + item.comments + ' - on' + '<span class="time"> ' + item.time + ' - as ' + '</span>' + '<span style="text-transform: uppercase;">' + item.interface + '</span>' + '<span> Interface</span>' + '<span class="close-img remove_commands" id="coolbutton" /><i class="far fa-times-circle"></i></span></span></li>';

      $("#sip_connect_msg" + newuuid).html(listcontent);

    });

  }
  removeportsip() {
    // //console.log('testing');
    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var routes = $('#removevalues').val();
    var getid = $('#removevalid').val();
    // alert(routes);
    // alert(getid);

    var newuuid = getid.toLowerCase();
    var id = btoa('WAN=' + newuuid);
    var uid = btoa(id);
    //console.log(uid);
    // return false;
    var sip_connect_remove = '{"action":"removeSipRouteCmd","route_key_id":"' + routes + '","hostname":"' + this.hostname + '","uid":"' + uid + '"}';
    this.websocket.send(sip_connect_remove);

  }

  removefiredatas() {
    var ids = $('#rmfirewallid').val();
    var ports = $('#rmfirewallport').val();
    var protocal = $('#rmfirewallportocol').val();
    var proces = $('#rmfirewallpro').val();
    var times = $('#rmfirewalltimes').val();


    var newuuid = ids.toLowerCase();
    var id = btoa('WAN=' + newuuid);
    var uid = btoa(id);
    //console.log(uid);
    // return false;
    var firewall_remove = ' {"action":"removePortChange","uid":"' + uid + '","new_port":"' + ports + '","hostname":"' + this.hostname + '","protocal":"' + protocal + '"}';
    this.websocket.send(firewall_remove);


  }



  showalldatas() {
    // {"wan": ["dhcp"], "lan": ["static", "192.168.101.1", "255.255.255.0", "192.168.101.1"], "uid": "VjBGT1BUY3dZak5rTlRBME16STBOQT09", "dns": ["nameserver", "8.8.8.8", "nameserver", "8.8.4.4"], "current_version": "1.237"}




    let socketData = $('#sendonload').val();
    let mData = JSON.parse(socketData);
     console.log(mData);

    var id = atob(mData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(newuuid);

    if (mData.lan1) {
      this.RRdevices(mData, newuuid);

    } else if (mData.lan) {
      this.otherdevices(mData, newuuid);


    } else if (mData.ipaddress) {
      this.nx16Device(mData, newuuid);

    }






  }


  RRdevices(mData, newuuid) {
    // ================================= LAN DISPLAY =======================================
    var rrlantoggle = mData.lan1[0];
    //console.log(rrlantoggle);

    var lantoggle = mData.lan2[0];
    $("#activation" + newuuid).prop('checked', true);


    var self = this;
    //console.log(mData);

    var lan1content = '';
    var newlan1content = '';
    if (mData.lan1status == 'up') {
      lan1content = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> LAN 1</a>';
      newlan1content = '<a href="#" style="margin: 0px" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
      $(".rrinterface-lan" + newuuid).html(lan1content);
      $("#rrinterface-newlan1" + newuuid).html(newlan1content);
    } else if (mData.lan1status == 'down') {
      lan1content = '<a href="#"><img class="network-active-img" src="assets/custom-images/lan-active-red.svg"> LAN 1</a>';
      newlan1content = '<a href="#"  style="margin: 0px"><img style="margin: 0px;margin-left: 3px;" class="network-active-img" src="assets/custom-images/lan-active-red.svg"></a>';
      $(".rrinterface-lan" + newuuid).html(lan1content);
      $("#rrinterface-newlan1" + newuuid).html(newlan1content);
    }



    var lan2content = '';
    var newlan2content = '';
    if (mData.lan2status == 'up') {
      lan2content = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> LAN 2</a>';
      newlan2content = '<a href="#" style="margin: 0px" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
      $(".interface-lan" + newuuid).html(lan2content);
      $("#interface-newlan2" + newuuid).html(newlan2content);
    } else if (mData.lan2status == 'down') {
      lan2content = '<a href="#"><img class="network-active-img" src="assets/custom-images/lan-active-red.svg"> LAN 2</a>';
      newlan2content = '<a href="#"  style="margin: 0px"><img style="margin: 0px;margin-left: 3px;" class="network-active-img" src="assets/custom-images/lan-active-red.svg"></a>';
      $(".interface-lan" + newuuid).html(lan2content);
      $("#interface-newlan2" + newuuid).html(newlan2content);
    }
    var wancontent = '';
    var newwancontent = '';
    if (mData.wanstatus == 'up') {
      wancontent = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> WAN</a>';
      newwancontent = '<a href="#" style="margin: 0px;" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
      $(".interface-wan" + newuuid).html(wancontent);
      $("#interface-newwan" + newuuid).html(newwancontent);
    } else if (mData.wanstatus == 'down') {
      wancontent = '<a href="#"><img class="network-active-img" src="assets/custom-images/lan-active-red.svg"> WAN</a>';
      newwancontent = '<a href="#" style="margin: 0px"><img style="margin: 0px;margin-left: 3px;" class="network-active-img" src="assets/custom-images/lan-active-red.svg"></a>';
      $(".interface-wan" + newuuid).html(wancontent);
      $("#interface-newwan" + newuuid).html(newwancontent);
    }


    $('#fireware-version' + newuuid).text('Firmware V' + mData.current_version);
    // $('#fireware-version' + newuuid).text('Firmware V' + mData.current_version);
    $('#cloud_val' + newuuid).text(mData.uid);
    $('#dev_ip' + newuuid).text(mData.internetIpAddress);
    this.ipaddresslocation(mData.internetIpAddress, newuuid);

    // if (lanadd !== 'NULL' && langate !== 'NULL' && lannets !== 'NULL') {



    // ========================= 256 RR device LAN 1 =========================


    if (rrlantoggle == 'static') {
      $("#rrLanAssignIP" + newuuid).prop('checked', true);
      $("#rrLanAutoIP" + newuuid).prop('checked', false);
    } else if (rrlantoggle == 'dhcp') {
      $("#rrLanAutoIP" + newuuid).prop('checked', true);
      this.ObtainRRToggle(newuuid, "", "");
      $("#rrLanAssignIP" + newuuid).prop('checked', false);
    }

    if (mData.lan1[1] != undefined && mData.lan1[1] != null) {
      var arraylanadd = mData.lan1[1].split('.');
      $('#lanaddress1' + newuuid).val(arraylanadd[0]);
      $('#lanaddress2' + newuuid).val(arraylanadd[1]);
      $('#lanaddress3' + newuuid).val(arraylanadd[2]);
      $('#lanaddress4' + newuuid).val(arraylanadd[3]);
    }

    if (mData.lan1[2] != undefined && mData.lan1[2] != null) {
      var arraylannets = mData.lan1[2].split('.');
      $('#lannetmask1' + newuuid).val(arraylannets[0]);
      $('#lannetmask2' + newuuid).val(arraylannets[1]);
      $('#lannetmask3' + newuuid).val(arraylannets[2]);
      $('#lannetmask4' + newuuid).val(arraylannets[3]);
    }

    if (mData.lan1[3] != undefined && mData.lan1[3] != null) {
      var arraylangate = mData.lan1[3].split('.');
      $('#langateway1' + newuuid).val(arraylangate[0]);
      $('#langateway2' + newuuid).val(arraylangate[1]);
      $('#langateway3' + newuuid).val(arraylangate[2]);
      $('#langateway4' + newuuid).val(arraylangate[3]);
    }

    // ========================= 256 RR device LAN 2 =========================


    if (lantoggle == 'static') {
      $("#LanAssignIP" + newuuid).prop('checked', true);
      $("#LanAutoIP" + newuuid).prop('checked', false);
    } else if (lantoggle == 'dhcp') {
      $("#LanAutoIP" + newuuid).prop('checked', true);
      this.ObtainToggle(newuuid, "", "");
      $("#LanAssignIP" + newuuid).prop('checked', false);
    }

    if (mData.lan2[1] != undefined && mData.lan2[1] != null) {
      var arraylanadd = mData.lan2[1].split('.');
      $('#lanaddress1' + newuuid).val(arraylanadd[0]);
      $('#lanaddress2' + newuuid).val(arraylanadd[1]);
      $('#lanaddress3' + newuuid).val(arraylanadd[2]);
      $('#lanaddress4' + newuuid).val(arraylanadd[3]);
    }

    if (mData.lan2[2] != undefined && mData.lan2[2] != null) {
      var arraylannets = mData.lan2[2].split('.');
      $('#lannetmask1' + newuuid).val(arraylannets[0]);
      $('#lannetmask2' + newuuid).val(arraylannets[1]);
      $('#lannetmask3' + newuuid).val(arraylannets[2]);
      $('#lannetmask4' + newuuid).val(arraylannets[3]);
    }

    if (mData.lan2[3] != undefined && mData.lan2[3] != null) {
      var arraylangate = mData.lan2[3].split('.');
      $('#langateway1' + newuuid).val(arraylangate[0]);
      $('#langateway2' + newuuid).val(arraylangate[1]);
      $('#langateway3' + newuuid).val(arraylangate[2]);
      $('#langateway4' + newuuid).val(arraylangate[3]);
    }

    // }

    //================================= WAN DISPLAY =======================================
    var wantoggle = mData.wan[0];
    if (mData.wan[1] != undefined && mData.wan[1] != null) {
      var arraywanadd = mData.wan[1].split('.');

      $('#wanaddress1' + newuuid).val(arraywanadd[0]);
      $('#wanaddress2' + newuuid).val(arraywanadd[1]);
      $('#wanaddress3' + newuuid).val(arraywanadd[2]);
      $('#wanaddress4' + newuuid).val(arraywanadd[3]);
    }

    if (mData.wan[2] != undefined && mData.wan[2] != null) {
      var arraywannets = mData.wan[2].split('.');

      $('#wannetmask1' + newuuid).val(arraywannets[0]);
      $('#wannetmask2' + newuuid).val(arraywannets[1]);
      $('#wannetmask3' + newuuid).val(arraywannets[2]);
      $('#wannetmask4' + newuuid).val(arraywannets[3]);
    }

    if (mData.wan[3] != undefined && mData.wan[3] != null) {
      var arraywangate = mData.wan[3].split('.');

      $('#wangateway1' + newuuid).val(arraywangate[0]);
      $('#wangateway2' + newuuid).val(arraywangate[1]);
      $('#wangateway3' + newuuid).val(arraywangate[2]);
      $('#wangateway4' + newuuid).val(arraywangate[3]);
    }

    if (wantoggle == 'static') {
      $("#WanAssignIP" + newuuid).prop('checked', true);
      $("#WanAutoIP" + newuuid).prop('checked', false);
    } else if (wantoggle == 'dhcp') {
      $("#WanAutoIP" + newuuid).prop('checked', true);
      this.ObtainWAN(newuuid, "", "");
      $("#WanAssignIP" + newuuid).prop('checked', false);
    }

    // ================================= DNS ADDRESS =====================================

    if (mData.dns[1] != undefined && mData.dns[1] != null) {
      var arraydnsadd = mData.dns[1].split('.');
      $('#dnsaddress1' + newuuid).val(arraydnsadd[0]);
      $('#dnsaddress2' + newuuid).val(arraydnsadd[1]);
      $('#dnsaddress3' + newuuid).val(arraydnsadd[2]);
      $('#dnsaddress4' + newuuid).val(arraydnsadd[3]);
    }
    // Swal.close();

  }

  otherdevices(mData, newuuid) {

    // ================================= LAN DISPLAY =======================================
    var lantoggle = mData.lan[0];
    $("#activation" + newuuid).prop('checked', true);

    var self = this;
    //console.log(mData);

    var lancontent = '';
    var newlancontent = '';
    if (mData.lanstatus == 'up') {
      lancontent = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> LAN</a>';
      newlancontent = '<a href="#" style="margin: 0px" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
      $(".interface-lan" + newuuid).html(lancontent);
      $("#interface-newlan" + newuuid).html(newlancontent);
    } else if (mData.lanstatus == 'down') {
      lancontent = '<a href="#"><img class="network-active-img" src="assets/custom-images/lan-active-red.svg"> LAN</a>';
      newlancontent = '<a href="#"  style="margin: 0px"><img style="margin: 0px;margin-left: 3px;" class="network-active-img" src="assets/custom-images/lan-active-red.svg"></a>';
      $(".interface-lan" + newuuid).html(lancontent);
      $("#interface-newlan" + newuuid).html(newlancontent);
    }
    var wancontent = '';
    var newwancontent = '';
    if (mData.wanstatus == 'up') {
      wancontent = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> WAN</a>';
      newwancontent = '<a href="#" style="margin: 0px;" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
      $(".interface-wan" + newuuid).html(wancontent);
      $("#interface-newwan" + newuuid).html(newwancontent);
    } else if (mData.wanstatus == 'down') {
      wancontent = '<a href="#"><img class="network-active-img" src="assets/custom-images/lan-active-red.svg"> WAN</a>';
      newwancontent = '<a href="#" style="margin: 0px"><img style="margin: 0px;margin-left: 3px;" class="network-active-img" src="assets/custom-images/lan-active-red.svg"></a>';
      $(".interface-wan" + newuuid).html(wancontent);
      $("#interface-newwan" + newuuid).html(newwancontent);
    }


    $('#fireware-version' + newuuid).text('Firmware V' + mData.current_version);
    $('#phone-system' + newuuid).text(mData.phonesys);
    $('#cloud_val' + newuuid).text(mData.uid);
    $('#dev_ip' + newuuid).text(mData.internetIpAddress);
    this.ipaddresslocation(mData.internetIpAddress, newuuid);

    // if (lanadd !== 'NULL' && langate !== 'NULL' && lannets !== 'NULL') {

    if (lantoggle == 'static') {
      $("#LanAssignIP" + newuuid).prop('checked', true);
      $("#LanAutoIP" + newuuid).prop('checked', false);
    } else if (lantoggle == 'dhcp') {
      $("#LanAutoIP" + newuuid).prop('checked', true);
      this.ObtainToggle(newuuid, "", "");
      $("#LanAssignIP" + newuuid).prop('checked', false);
    }
    if (mData.lan[1] != undefined && mData.lan[1] != null) {
      var arraylanadd = mData.lan[1].split('.');
      $('#lanaddress1' + newuuid).val(arraylanadd[0]);
      $('#lanaddress2' + newuuid).val(arraylanadd[1]);
      $('#lanaddress3' + newuuid).val(arraylanadd[2]);
      $('#lanaddress4' + newuuid).val(arraylanadd[3]);
    }

    if (mData.lan[2] != undefined && mData.lan[2] != null) {
      var arraylannets = mData.lan[2].split('.');
      $('#lannetmask1' + newuuid).val(arraylannets[0]);
      $('#lannetmask2' + newuuid).val(arraylannets[1]);
      $('#lannetmask3' + newuuid).val(arraylannets[2]);
      $('#lannetmask4' + newuuid).val(arraylannets[3]);
    }

    if (mData.lan[3] != undefined && mData.lan[3] != null) {
      var arraylangate = mData.lan[3].split('.');
      $('#langateway1' + newuuid).val(arraylangate[0]);
      $('#langateway2' + newuuid).val(arraylangate[1]);
      $('#langateway3' + newuuid).val(arraylangate[2]);
      $('#langateway4' + newuuid).val(arraylangate[3]);
    }

    // }

    //================================= WAN DISPLAY =======================================
    var wantoggle = mData.wan[0];
    if (mData.wan[1] != undefined && mData.wan[1] != null) {
      var arraywanadd = mData.wan[1].split('.');

      $('#wanaddress1' + newuuid).val(arraywanadd[0]);
      $('#wanaddress2' + newuuid).val(arraywanadd[1]);
      $('#wanaddress3' + newuuid).val(arraywanadd[2]);
      $('#wanaddress4' + newuuid).val(arraywanadd[3]);
    }

    if (mData.wan[2] != undefined && mData.wan[2] != null) {
      var arraywannets = mData.wan[2].split('.');

      $('#wannetmask1' + newuuid).val(arraywannets[0]);
      $('#wannetmask2' + newuuid).val(arraywannets[1]);
      $('#wannetmask3' + newuuid).val(arraywannets[2]);
      $('#wannetmask4' + newuuid).val(arraywannets[3]);
    }

    if (mData.wan[3] != undefined && mData.wan[3] != null) {
      var arraywangate = mData.wan[3].split('.');

      $('#wangateway1' + newuuid).val(arraywangate[0]);
      $('#wangateway2' + newuuid).val(arraywangate[1]);
      $('#wangateway3' + newuuid).val(arraywangate[2]);
      $('#wangateway4' + newuuid).val(arraywangate[3]);
    }

    if (wantoggle == 'static') {
      $("#WanAssignIP" + newuuid).prop('checked', true);
      $("#WanAutoIP" + newuuid).prop('checked', false);
    } else if (wantoggle == 'dhcp') {
      $("#WanAutoIP" + newuuid).prop('checked', true);
      this.ObtainWAN(newuuid, "", "");
      $("#WanAssignIP" + newuuid).prop('checked', false);
    }

    // ================================= DNS ADDRESS =====================================

    if (mData.dns[1] != undefined && mData.dns[1] != null) {
      var arraydnsadd = mData.dns[1].split('.');
      $('#dnsaddress1' + newuuid).val(arraydnsadd[0]);
      $('#dnsaddress2' + newuuid).val(arraydnsadd[1]);
      $('#dnsaddress3' + newuuid).val(arraydnsadd[2]);
      $('#dnsaddress4' + newuuid).val(arraydnsadd[3]);
    }
    // Swal.close();
  }



  latestversion(devices_ids) {
    $("#" + 'defaultAccordionOne-' + devices_ids).slideToggle();
    $("#rounded-pills-icon-contact-tab" + devices_ids).click();
    // $('#'+rounded-pills-icon-contact-tab+device_ids).

  }

  settings(device_ids, index) {
    $('#use_device_as_3cx' + device_ids).val();
    $('#use_device_as_sbc' + device_ids).val();

  }

  shownewupdates() {

    let newupdatevalues = $('#newupdates').val();
    //console.log(newupdatevalues);
    let UData = JSON.parse(newupdatevalues);
    var id = atob(UData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(newuuid);
    // available;
    // not_available;

    var data = 'click to Update' + UData.server_version;
    // alert(UData.server_version);
    $("#update-button" + newuuid).text(data);
    if (UData.current_version != UData.server_version) {
      //console.log(UData.status);
      $("#available" + newuuid).removeClass("testing2").addClass("activation");
      this.upd_avail = true;
    } else {
      $("#not_available" + newuuid).removeClass("testing2").addClass("activation");
      this.upd_cpl = true;
    }
  }

  showfirewalldata() {
    let firewallData = $('#firwalldatas').val();
    let FData = JSON.parse(firewallData);
    var id = atob(FData.uid);
    id = atob(id);
    var uuid = id.split('=')[1];
    var newuuid = uuid.toUpperCase();
    //console.log(newuuid);

    //console.log(firewallData);
    var port_type = FData.protocal;
    if (port_type == 'tcp') {
      $("#allow_tcp" + newuuid).prop('checked', true);
      $("#allow_udp" + newuuid).prop('checked', false);
      $("#allow_both" + newuuid).prop('checked', false);
    } else if (port_type == 'udp') {
      $("#allow_tcp" + newuuid).prop('checked', false);
      $("#allow_udp" + newuuid).prop('checked', true);
      $("#allow_both" + newuuid).prop('checked', false);
    } else if (port_type == 'both') {
      $("#allow_tcp" + newuuid).prop('checked', false);
      $("#allow_udp" + newuuid).prop('checked', false);
      $("#allow_both" + newuuid).prop('checked', true);
    }

    this.firewall_changes = FData.port_data;
    if(this.firewall_changes == '' || this.firewall_changes ==null || !this.firewall_changes){
      $("#div3" + newuuid).html('');
      // alert(newuuid);
    }
    var listcontent = '';
    this.firewall_changes.forEach((item) => {
      var port = "'" + item.port + "'";
      var id = "'" + newuuid + "'";
      var protocol = "'" + item.protocal + "'";
      var process = "'" + item.process + "'";
      var time = "'" + item.cmd_time + "'";
      listcontent += '<li><span class="box-content">' + item.port + ' <span class="time">' + item.protocal + ' port ' + item.process + ' - on ' + item.cmd_time + '</span>' + '<span class="close-img remove_commands" onclick="removefirewallports(' + id + ',' + port + ',' + protocol + ',' + process + ',' + time + ')"><i class="far fa-times-circle"></i></span></span></li>';
      $("#div3" + newuuid).html(listcontent).click(function () {
      });
    });

  }
  removeport() {
    //console.log('hello testing');
  }

  refresh(devices_id, index) {
    // this.sendOnload(devices_id);
    this.ipconfiglist(devices_id);
    this.firewalllist(devices_id);
    this.settinglist(devices_id);
    this.nx16settinglist(devices_id);
    this.updatelist(devices_id);

    this.sipconnectlist(devices_id);
  }

  reboot(devices_ids, index) {

    Swal.fire({
      title: 'Reboot Requested',
      text: 'The reboot process has been initiated. Please wait for the device to reboot.',
      icon: 'info',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    var wanip = devices_ids.toLowerCase();
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    //  var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);
    var reboot = '{"action":"commands_3cx","command_type":"reboot_device","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(reboot);


    this.websocket.addEventListener('message', (event) => {


      if (event.data === 'reboot_complete') {
        Swal.fire({
          title: 'Reboot Complete',
          text: 'The device has been successfully rebooted.',
          icon: 'success',
        });
      }
    });
  }


  poweroff(devices_ids, index) {
    Swal.fire({
      title: 'Power Off Confirmation',
      text: 'Are you sure you want to power off the device?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, power off',
      cancelButtonText: 'No, cancel',
    })
    Swal.fire('Powering Off...', 'The device power off process has been initiated.', 'info');


    var wanip = devices_ids.toLowerCase();
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    //  var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);
    var poweroffs = '{"action":"commands_3cx","command_type":"power_off","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(poweroffs);
  }



  firewallconfig(devices_ids, index) {

    devices_ids = $('#uuidvalue' + index).text();
    //console.log(devices_ids);

    let direct_ip = '';
    let firewallAccess1 = $("#input1").val();
    let firewallAccess2 = $("#input2").val();
    let firewallAccess3 = $("#input3").val();
    let firewallAccess4 = $("#input4").val();

    let firewalls1 = $("#slashinput1").val();
    let firewalls2 = $("#slashinput2").val();
    let firewalls3 = $("#slashinput3").val();
    let firewalls4 = $("#slashinput4").val();
    let firewalls5 = $("#slashinput5").val();



    let sipport_data = $("#sipport").val();
    //console.log(sipport_data);

    if (sipport_data == undefined) {
      sipport_data = null;
    }

    var allowAll = $("#allow_all_ip" + index).is(":checked");
    let allowallvalue = '';
    if (allowAll = true) {
      allowallvalue = 'all_ip';
    }
    var allowIp = $("#allow_ip" + index).is(":checked");
    var AllowIPRange = $("#allow_ip_range" + index).is(":checked");
    //console.log(allowIp, 'allowip');
    //console.log(AllowIPRange, 'allowiprange');
    //  return false;

    if (allowIp == true) {
      direct_ip = firewallAccess1 + '.' + firewallAccess2 + '.' + firewallAccess3 + '.' + firewallAccess4;
      allowallvalue = 'direct'
    }
    if (AllowIPRange == true) {
      direct_ip = firewalls1 + '.' + firewalls2 + '.' + firewalls3 + '.' + firewalls4 + '/' + firewalls5;
      allowallvalue = 'range'
    }
    //console.log(direct_ip);



  }

  clicktoupdate(devices_ids, index) {
    Swal.fire({
      title: 'Please Wait',
      html: '<b></b> minutes, device configure as  SBC & get Reboot',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 180000,
      onOpen: () => {
        var minute = 2;
        var sec = 59;
        var intervalId = setInterval(function () {
          // document.getElementById("timer").innerHTML = minute + " : " + sec;
          //console.log(minute + " : " + sec);
          var timers = minute + " : " + sec;
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = timers
            }
            sec--;
            if (sec == 0o0) {
              minute--;
              sec = 60;
              if (minute == -1) {
                // minute = 0;
                clearInterval(intervalId);
              }
            }
          }
        }, 1000);
        Swal.showLoading();
      }
    }).then(
      () => { },
      (dismiss) => {
        if (dismiss === 'timer') {
          //console.log('closed by timer!!!!');
          Swal.fire({
            title: 'Devices Updated!',
            // type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            allowOutsideClick: false
          })
        }
      }
    );
    // Swal.fire({
    //   // title: 'Updated',
    //   //   position: 'top-end',
    //   title: 'Please Wait....',
    //   imageUrl: "assets/custom-images/pleasewait.gif",
    //   imageHeight: 80,
    //   imageWidth: 80,
    //   showConfirmButton: false,
    //   allowOutsideClick: false
    // });
    var wanip = devices_ids.toLowerCase();
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    //  var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);

    var clickupdate = '{"action":"versionUpdate","hostname":"' + this.hostname + '","uid":"' + id + '"}';
    this.websocket.send(clickupdate);
  }

  updatefirewallvalue(devices_ids, index) {
    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    const sipport: Number = +$('#sipport' + index).val();
    // devices_ids = $('#uuidvalue' + index).text();
    let protocols = '';
    if ($("#allow_tcp" + index).is(":checked") == true) {
      protocols = 'tcp';
    } else if ($("#allow_udp" + index).is(":checked") == true) {
      protocols = 'udp';
    } else if ($("#allow_both" + index).is(":checked") == true) {
      protocols = 'both';
    }

    // {"action":"allowNewPortChange", "uid":"","new_port":"5088","hostname":"localhost","protocal":"tcp"}

    var wanip = devices_ids.toLowerCase();
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    //  var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);
    // var firewallvalue = '{"action":"allowNewPortChange", "uid":"'+id+'","new_port":"'+sipport+'","hostname":"localhost","protocal":"'+protocols+'"}';
    var firewallvalue = '{"action":"allowNewPortChange","uid":"' + id + '","new_port":"' + sipport + '","hostname":"' + this.hostname + '","protocal":"' + protocols + '"}';
    //console.log(firewallvalue);
    this.websocket.send(firewallvalue);

  }



  sip_connect_update(devices_ids, index) {
    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    // devices_ids = $('#uuidvalue' + index).text();
    let allow_direct_ip = '';
    let direct_ip = '';
    let sipconnectAccess1 = $("#sip_input1" + index).val();
    let sipconnectAccess2 = $("#sip_input2" + index).val();
    let sipconnectAccess3 = $("#sip_input3" + index).val();
    let sipconnectAccess4 = $("#sip_input4" + index).val();


    let sipconnect1 = $("#sip_connect1" + index).val();
    let sipconnect2 = $("#sip_connect2" + index).val();
    let sipconnect3 = $("#sip_connect3" + index).val();
    let sipconnect4 = $("#sip_connect4" + index).val();
    let sipconnect5 = $("#sip_connect5" + index).val();





    let LAN = $("#network_type_lan" + index).is(":checked");
    let WAN = $("#network_type_wan" + index).is(":checked");
    var allowIp = $("#allow_all_ip_sip" + index).is(":checked");
    var AllowIPRange = $("#allow_ip_range_sip" + index).is(":checked");
    var staticoptions = $("#staticroutes" + index).is(":checked");
    let allowingType = '';
    let newinterface = '';



    if (AllowIPRange == true) {
      if (sipconnect1 !== '' && sipconnect2 !== '' && sipconnect3 !== '' && sipconnect4 !== '' && sipconnect5 !== '') {
        $('#sip-connect-slash-error' + index).remove();
      } else {
        if ($('#sip-connect-slash-error' + index + ' span').length === 0) {
          var examsList = $('#sip-connect-slash-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (allowIp == true) {
      if (sipconnectAccess1 !== '' && sipconnectAccess2 !== '' && sipconnectAccess3 !== '' && sipconnectAccess4 !== '') {
        $('#sip-connect-error' + index).remove();
      } else {
        if ($('#sip-connect-error' + index + ' span').length === 0) {
          var examsList = $('#sip-connect-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (allowIp == true) {
      allow_direct_ip = sipconnectAccess1 + '.' + sipconnectAccess2 + '.' + sipconnectAccess3 + '.' + sipconnectAccess4;
      allowingType = 'direct';
    }
    if (AllowIPRange == true) {
      direct_ip = sipconnect1 + '.' + sipconnect2 + '.' + sipconnect3 + '.' + sipconnect4 + '/' + sipconnect5;
      allowingType = 'range';
    }
    //console.log(direct_ip);
    //console.log(staticoptions);
    //console.log(WAN);
    //console.log(LAN);
    // return false;

    var wanip = devices_ids.toLowerCase();
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    //  var id = btoa('WAN=70b3d5043374'); // 143

    id = btoa(id);

    if (LAN == true) {
      newinterface = 'lan';
    } else if (WAN == true) {
      newinterface = 'wan';
    }
    //var data = '{"action":"update_sip_connect","allow_type":"range","direct_ip":"","ip_range":"111.111.111.15/17","hostname":"localhost","static_route":"true","interface":"wan","uid":"'+id+'"}';
    var sipconnectsend = '{"action":"update_sip_connect","allow_type":"' + allowingType + '","direct_ip":"' + allow_direct_ip + '","ip_range":"' + direct_ip + '","hostname":"' + this.hostname + '","static_route":"' + staticoptions + '","interface":"' + newinterface + '","uid":"' + id + '"}';
    this.websocket.send(sipconnectsend);

  }




  updateIpLan(devices_id, index, cmd_types) {


    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    // $deviceId: uuid!, $ip_lan_address: String, $ip_lan_netmask: String, $ip_lan_gateway: String, $lan_ip: String!
    devices_id = devices_id.toLowerCase();
    // ================================= LAN DATA ========================================
    let ipaddressLAN = '';
    let netmaskLAN = '';
    let gatewayLAN = '';
    let command_types = cmd_types;
    //console.log(command_types);


    let lanaddr1 = $("#lanaddress1" + index).val();
    let lanaddr2 = $("#lanaddress2" + index).val();
    let lanaddr3 = $("#lanaddress3" + index).val();
    let lanaddr4 = $("#lanaddress4" + index).val();

    let Lannet1 = $("#lannetmask1" + index).val();
    let Lannet2 = $("#lannetmask2" + index).val();
    let Lannet3 = $("#lannetmask3" + index).val();
    let Lannet4 = $("#lannetmask4" + index).val();

    let langate1 = $("#langateway1" + index).val();
    let langate2 = $("#langateway2" + index).val();
    let langate3 = $("#langateway3" + index).val();
    let langate4 = $("#langateway4" + index).val();
    let lan_ip_value = '';
    let lan_ips_auto = $("#LanAutoIP" + index).is(":checked");
    let lan_ips_static = $("#LanAssignIP" + index).is(":checked");


    if (lan_ips_static == true) {
      //console.log(lanaddr1, lanaddr2, lanaddr3, lanaddr4);
      if (lanaddr1 !== '' && lanaddr2 !== '' && lanaddr3 !== '' && lanaddr4 !== '') {
        // if(lanaddr1.length === 0 && lanaddr2.length === 0 && lanaddr3.length === 0 && lanaddr4.length === 0){
        $('#lan-error-list' + index).remove();
      } else {
        if ($('#lan-error-list' + index + ' span').length === 0) {
          var examsList = $('#lan-error-list' + index);
          var clone = examsList.append($('<span  class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          // return false;
        }
      }
    }
    if (lan_ips_static == true) {
      //console.log(Lannet1, Lannet2, Lannet3, Lannet4);
      if (Lannet1 !== '' && Lannet2 !== '' && Lannet3 !== '' && Lannet4 !== '') {
        // if(Lannet1.length === 0 && Lannet2.length === 0 && Lannet3.length === 0 && Lannet4.length === 0){
        $('#lan-netmask-error' + index).remove();
      } else {
        // //console.log($('#lan-netmask-error' +index+' span'));
        if ($('#lan-netmask-error' + index + ' span').length === 0) {
          var examsList = $('#lan-netmask-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          // return false;
        }
      }
    }
    if (lan_ips_static == true) {
      //console.log(langate1, langate2, langate3, langate4);
      // if(langate1 !== '' && langate2 !=='' && langate3 !== '' && langate4 !== ''){
      if (langate1.length > 0 && langate2.length > 0 && langate3.length > 0 && langate4.length > 0) {
        $('#lan-gateway-error' + index).remove();
      } else {
        if ($('#lan-gateway-error' + index + ' span').length === 0) {
          var examsList = $('#lan-gateway-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (lan_ips_static == true) {
      lan_ip_value = 'manual';
    } else if (lan_ips_auto == true) {
      lan_ip_value = 'automatic';
    }

    if (lan_ips_static == true) {
      ipaddressLAN = lanaddr1 + '.' + lanaddr2 + '.' + lanaddr3 + '.' + lanaddr4;
      netmaskLAN = Lannet1 + '.' + Lannet2 + '.' + Lannet3 + '.' + Lannet4;
      gatewayLAN = langate1 + '.' + langate2 + '.' + langate3 + '.' + langate4;
      // command_types = 'ip_config_lan';
    }
    // if(ipaddressLAN == null && )
    // this.updatelanbuttons = true;

    //============================= WAN VALUES =========================================  
    let ipaddressWAN = '';
    let netmaskWAN = '';
    let gatewayWAN = '';

    let wanaddr1 = $("#wanaddress1" + index).val();
    let wanaddr2 = $("#wanaddress2" + index).val();
    let wanaddr3 = $("#wanaddress3" + index).val();
    let wanaddr4 = $("#wanaddress4" + index).val();

    let wannet1 = $("#wannetmask1" + index).val();
    let wannet2 = $("#wannetmask2" + index).val();
    let wannet3 = $("#wannetmask3" + index).val();
    let wannet4 = $("#wannetmask4" + index).val();

    let wangate1 = $("#wangateway1" + index).val();
    let wangate2 = $("#wangateway2" + index).val();
    let wangate3 = $("#wangateway3" + index).val();
    let wangate4 = $("#wangateway4" + index).val();
    let wan_ip_value = '';
    let wan_ips_auto = $("#WanAutoIP" + index).is(":checked");
    let wan_ips_static = $("#WanAssignIP" + index).is(":checked");

    if (wan_ips_static == true) {
      //console.log(wanaddr1, wanaddr2, wanaddr3, wanaddr4);
      if (wanaddr1 !== '' && wanaddr2 !== '' && wanaddr3 !== '' && wanaddr4 !== '') {
        $('#wan-error-list' + index).remove();
      } else {
        if ($('#wan-error-list' + index + ' span').length === 0) {
          var examsList = $('#wan-error-list' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }
    if (wan_ips_static == true) {
      // //console.log(wannet1, wannet2, wannet3, wannet4);
      if (wannet1 !== '' && wannet2 !== '' && wannet3 !== '' && wannet4 !== '') {
        $('#wan-netmask-error' + index).remove();
      } else {
        if ($('#wan-netmask-error' + index + ' span').length === 0) {
          var examsList = $('#wan-netmask-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }
    if (wan_ips_static == true) {
      // //console.log(wangate1, wangate2, wangate3, wangate4);
      if (wangate1 !== '' && wangate2 !== '' && wangate3 !== '' && wangate4 !== '') {
        $('#wan-gateway-error' + index).remove();
      } else {
        if ($('#wan-gateway-error' + index + ' span').length === 0) {
          var examsList = $('#wan-gateway-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (wan_ips_static == true) {
      wan_ip_value = 'manual';
    } else if (wan_ips_auto == true) {
      wan_ip_value = 'automatic';
    }



    if (wan_ips_static == true) {
      ipaddressWAN = wanaddr1 + '.' + wanaddr2 + '.' + wanaddr3 + '.' + wanaddr4;
      netmaskWAN = wannet1 + '.' + wannet2 + '.' + wannet3 + '.' + wannet4;
      gatewayWAN = wangate1 + '.' + wangate2 + '.' + wangate3 + '.' + wangate4;
      // command_types = 'ip_config_wan';
      //console.log(wanaddr1, wanaddr2, wanaddr3, wanaddr4);
      // if (wanaddr1.length > 0 && wanaddr2.length > 0 && wanaddr3.length > 0 && wanaddr4.length > 0 && wannet1.length > 0 && wannet2.length > 0 && wannet3.length > 0 && wannet4.length > 0 && wangate1.length > 0 && wangate2.length > 0 && wangate3.length > 0 && wangate4.length > 0) {
      // }  
    }

    // ========================== DNS VALUES ======================================


    let dnsaddr1 = $("#dnsaddress1" + index).val();
    let dnsaddr2 = $("#dnsaddress2" + index).val();
    let dnsaddr3 = $("#dnsaddress3" + index).val();
    let dnsaddr4 = $("#dnsaddress4" + index).val();
    let ipaddressDNS = ''
    if (dnsaddr1 !== '' && dnsaddr2 !== '' && dnsaddr3 !== '' && dnsaddr4 !== '') {
      ipaddressDNS = dnsaddr1 + '.' + dnsaddr2 + '.' + dnsaddr3 + '.' + dnsaddr4;
      // command_types = 'ip_config_dns';
      $('#DNS-address-error' + index).remove();
    } else {
      if ($('#DNS-address-error' + index + ' span').length === 0) {
        var examsList = $('#DNS-address-error' + index);
        var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
        return false;
      }
    }
    // if (lanaddr1.length > 0 && lanaddr2.length > 0 && lanaddr3.length > 0 && lanaddr4.length > 0 && Lannet1.length > 0 && Lannet2.length > 0 && Lannet3.length > 0 && Lannet4.length > 0 && langate1.length > 0 && langate2.length > 0 && langate3.length > 0 && langate4.length > 0) {

    var id = btoa('WAN=' + devices_id); // 190


    id = btoa(id);
    var sendLanipconfig = '{"action":"update_ip_config","uid":"' + id + '","lan_ip":"' + lan_ip_value + '","wan_ip":"' + wan_ip_value + '","ip_dns1":"' + ipaddressDNS + '","hostname":"' + this.hostname + '","ip_dns2":"8.8.4.4","ip_lan_gateway":"' + gatewayLAN + '","ip_lan_netmask":"' + netmaskLAN + '","ip_lan_address":"' + ipaddressLAN + '","ip_wan_address":"' + ipaddressWAN + '","ip_wan_gateway":"' + gatewayWAN + '","ip_wan_netmask":"' + netmaskWAN + '","command_type":"' + command_types + '"}';

    //console.log(sendLanipconfig);
    this.websocket.send(sendLanipconfig);


  }

  updateSBCIpLan(devices_id, index, cmd_types) {


    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    // $deviceId: uuid!, $ip_lan_address: String, $ip_lan_netmask: String, $ip_lan_gateway: String, $lan_ip: String!
    devices_id = devices_id.toLowerCase();
    // ================================= LAN DATA ========================================
    let ipaddressLAN = '';
    let netmaskLAN = '';
    let gatewayLAN = '';
    let command_types = cmd_types;
    //console.log(command_types);
    //console.log(index);

    let lanaddr1 = $("#lanaddress1" + index).val();
    let lanaddr2 = $("#lanaddress2" + index).val();
    let lanaddr3 = $("#lanaddress3" + index).val();
    let lanaddr4 = $("#lanaddress4" + index).val();



    let Lannet1 = $("#lannetmask1" + index).val();
    // let Lannet2 = $("#lannetmask2" + index).val();
    // let Lannet3 = $("#lannetmask3" + index).val();
    // let Lannet4 = $("#lannetmask4" + index).val();

    let langate1 = $("#langateway1" + index).val();
    let langate2 = $("#langateway2" + index).val();
    let langate3 = $("#langateway3" + index).val();
    let langate4 = $("#langateway4" + index).val();
    let lan_ip_value = '';
    let lan_ips_auto = $("#nx16LanAutoIP" + index).is(":checked");
    let lan_ips_static = $("#nx16LanAssignIP" + index).is(":checked");

    //console.log(lan_ips_static);
    //console.log(lan_ips_auto);



    if (lan_ips_static == true) {
      //console.log(lanaddr1, lanaddr2, lanaddr3, lanaddr4);
      if (lanaddr1 !== '' && lanaddr2 !== '' && lanaddr3 !== '' && lanaddr4 !== '') {

        $('#lan-error-list' + index).remove();
      } else {
        if ($('#lan-error-list' + index + ' span').length === 0) {
          var examsList = $('#lan-error-list' + index);
          var clone = examsList.append($('<span  class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          // return false;
        }
      }
    }

    if (lan_ips_static == true) {
      if (Lannet1 !== '') {
        $('#lan-netmask-error' + index).remove();
      } else {
        if ($('#lan-netmask-error' + index + ' span').length === 0) {
          var examsList = $('#lan-netmask-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          // return false;
        }
      }

    }
    if (lan_ips_static == true) {
      //console.log(langate1, langate2, langate3, langate4);
      // if(langate1 !== '' && langate2 !=='' && langate3 !== '' && langate4 !== ''){
      if (langate1.length > 0 && langate2.length > 0 && langate3.length > 0 && langate4.length > 0) {
        $('#lan-gateway-error' + index).remove();
      } else {
        if ($('#lan-gateway-error' + index + ' span').length === 0) {
          var examsList = $('#lan-gateway-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (lan_ips_static == true) {
      lan_ip_value = 'static';
    } else if (lan_ips_auto == true) {
      lan_ip_value = 'automatic';
    }

    if (lan_ips_static == true) {
      ipaddressLAN = lanaddr1 + '.' + lanaddr2 + '.' + lanaddr3 + '.' + lanaddr4;
      netmaskLAN = Lannet1 + '.';
      gatewayLAN = langate1 + '.' + langate2 + '.' + langate3 + '.' + langate4;
      // command_types = 'ip_config_lan';
    }



    // ========================== DNS VALUES ======================================


    let dnsaddr1 = $("#dnsaddress1" + index).val();
    let dnsaddr2 = $("#dnsaddress2" + index).val();
    let dnsaddr3 = $("#dnsaddress3" + index).val();
    let dnsaddr4 = $("#dnsaddress4" + index).val();
    let ipaddressDNS = ''
    if (dnsaddr1 !== '' && dnsaddr2 !== '' && dnsaddr3 !== '' && dnsaddr4 !== '') {
      ipaddressDNS = dnsaddr1 + '.' + dnsaddr2 + '.' + dnsaddr3 + '.' + dnsaddr4;
      // command_types = 'ip_config_dns';
      $('#DNS-address-error' + index).remove();
    } else {
      if ($('#DNS-address-error' + index + ' span').length === 0) {
        var examsList = $('#DNS-address-error' + index);
        var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
        return false;
      }
    }
    var id = btoa('WAN=' + devices_id); // 190

    netmaskLAN = netmaskLAN.replace(".", "")

    id = btoa(id);
    var sendLanipconfig = '{"action":"update_ip_config","uid":"' + id + '","lan_ip":"' + lan_ip_value + '","ip_dns":"' + ipaddressDNS + '","hostname":"' + this.hostname + '","ip_lan_gateway":"' + gatewayLAN + '","ip_lan_netmask":"' + netmaskLAN + '","ip_lan_address":"' + ipaddressLAN + '","lan_ip_value":"' + lan_ip_value + '"}';

    //console.log(sendLanipconfig);
    //  return false
    this.websocket.send(sendLanipconfig);

    // setTimeout(() => { location.reload();  }, 100);

  }

  updateIpWan(devices_id, index) {
    // $deviceId: uuid!, $ip_lan_address: String, $ip_lan_netmask: String, $ip_lan_gateway: String, $lan_ip: String!
    // devices_id = $('#uuidvalue' + index).text();
    let ipaddressWAN = '';
    let netmaskWAN = '';
    let gatewayWAN = '';

    let wanaddr1 = $("#wanaddress1" + index).val();
    let wanaddr2 = $("#wanaddress2" + index).val();
    let wanaddr3 = $("#wanaddress3" + index).val();
    let wanaddr4 = $("#wanaddress4" + index).val();

    let wannet1 = $("#wannetmask1" + index).val();
    let wannet2 = $("#wannetmask2" + index).val();
    let wannet3 = $("#wannetmask3" + index).val();
    let wannet4 = $("#wannetmask4" + index).val();

    let wangate1 = $("#wangateway1" + index).val();
    let wangate2 = $("#wangateway2" + index).val();
    let wangate3 = $("#wangateway3" + index).val();
    let wangate4 = $("#wangateway4" + index).val();
    let wan_ip_value = '';
    let wan_ips_auto = $("#WanAutoIP" + index).is(":checked");
    let wan_ips_static = $("#WanAssignIP" + index).is(":checked");

    if (wan_ips_static == true) {
      //console.log(wanaddr1, wanaddr2, wanaddr3, wanaddr4);
      if (wanaddr1 !== '' && wanaddr2 !== '' && wanaddr3 !== '' && wanaddr4 !== '') {
        $('#wan-error-list' + index).remove();
      } else {
        if ($('#wan-error-list' + index + ' span').length === 0) {
          var examsList = $('#wan-error-list' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }
    if (wan_ips_static == true) {
      // //console.log(wannet1, wannet2, wannet3, wannet4);
      if (wannet1 !== '' && wannet2 !== '' && wannet3 !== '' && wannet4 !== '') {
        $('#wan-netmask-error' + index).remove();
      } else {
        if ($('#wan-netmask-error' + index + ' span').length === 0) {
          var examsList = $('#wan-netmask-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }
    if (wan_ips_static == true) {
      // //console.log(wangate1, wangate2, wangate3, wangate4);
      if (wangate1 !== '' && wangate2 !== '' && wangate3 !== '' && wangate4 !== '') {
        $('#wan-gateway-error' + index).remove();
      } else {
        if ($('#wan-gateway-error' + index + ' span').length === 0) {
          var examsList = $('#wan-gateway-error' + index);
          var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
          return false;
        }
      }
    }

    if (wan_ips_static == true) {
      ipaddressWAN = wanaddr1 + '.' + wanaddr2 + '.' + wanaddr3 + '.' + wanaddr4;
      netmaskWAN = wannet1 + '.' + wannet2 + '.' + wannet3 + '.' + wannet4;
      gatewayWAN = wangate1 + '.' + wangate2 + '.' + wangate3 + '.' + wangate4;
      wan_ip_value = 'static';
      //console.log(wanaddr1, wanaddr2, wanaddr3, wanaddr4);
      if (wanaddr1.length > 0 && wanaddr2.length > 0 && wanaddr3.length > 0 && wanaddr4.length > 0 && wannet1.length > 0 && wannet2.length > 0 && wannet3.length > 0 && wannet4.length > 0 && wangate1.length > 0 && wangate2.length > 0 && wangate3.length > 0 && wangate4.length > 0) {
        // return false;


      }

    } else if (wan_ips_auto == true) {
      wan_ip_value = 'automatic';
      ipaddressWAN = '';
      netmaskWAN = '';
      gatewayWAN = '';


    }



  }


  updateIpDNS(device_id, index) {
    let dnsaddr1 = $("#dnsaddress1" + index).val();
    let dnsaddr2 = $("#dnsaddress2" + index).val();
    let dnsaddr3 = $("#dnsaddress3" + index).val();
    let dnsaddr4 = $("#dnsaddress4" + index).val();
    let ipaddressDNS = ''
    if (dnsaddr1 !== '' && dnsaddr2 !== '' && dnsaddr3 !== '' && dnsaddr4 !== '') {
      ipaddressDNS = dnsaddr1 + '.' + dnsaddr2 + '.' + dnsaddr3 + '.' + dnsaddr4;
      $('#DNS-address-error' + index).remove();
    } else {
      if ($('#DNS-address-error' + index + ' span').length === 0) {
        var examsList = $('#DNS-address-error' + index);
        var clone = examsList.append($('<span class="color: red !important;font-size:15px !important;letter-spacing:1px !important;">fill all the field</span>').clone(true));
        return false;
      }
    }
    if (dnsaddr1 > 0 && dnsaddr2 > 0 && dnsaddr3 > 0 && dnsaddr4 > 0) {

    }
  }


  getsbdevices(devices, index) {

    this.sbcuuid = index;
    //console.log('testing');
    let SBC = $("#use_device_as_sbc" + devices).is(":checked");
    let CX = $("#use_device_as_3cx" + devices).is(":checked");
    //console.log(SBC);
    // data-toggle="modal"   
    //console.log(CX);
    if (SBC == true) {
      this.sbcdevices_id = devices;
      this.sbc_index = index;
      $("#clickmodal").click();
      //console.log('hello');
    }
    if (CX == true) {
      $("#changetosbc").click();
      //console.log('hiii');
    }
  }

  three_cx_settings(devices_ids, index) {


  }



  setuuid1(addr) {
    this.sbcuuid = addr;

    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait...UnInstalling.',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });

    var wanip = this.sbcuuid.toLowerCase();
    var id = btoa('WAN=' + wanip);
    id = btoa(id);
    var parser = document.createElement('a');
    parser.href = this.sbcformdata.value.sbcweb1;
    // var sbchostname = parser.hostname;
    var sbchostname = "localhost";
    //console.log(sbchostname);

    //  var sendsbc = '{"action":"commands_3cx","hostname":"' + sbchostname + '","uid":"' + id + '"}';
    var sendsbc = '{"action":"commands_3cx","hostname":"' + sbchostname + '","uid":"' + id + '","command_type":"uninstall_sbc"}';

    this.websocket.send(sendsbc);
    setTimeout(() => { location.reload(); }, 120000);
  }


  sbc_install() {
    // alert("hello");

    $('#addContactModal1').modal('hide');

    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    //console.log(this.sbcformdata.value.sbcweb1);
    // return false;
    //console.log(this.sbcuuid);


    var wanip = this.sbcuuid.toLowerCase();
    var id = btoa('WAN=' + wanip);
    id = btoa(id);


    let SBC = $("#use_device_as_sbc" + this.sbc_index).is(":checked");

    //console.log(this.sbcformdata.value.sbcpass1);
    //console.log(SBC);
    // return false;
    var parser = document.createElement('a');
    parser.href = this.sbcformdata.value.sbcweb1;
    var sbchostname = parser.hostname;
    var prov_link = this.sbcformdata.value.sbcweb1 + "/sbc/" + this.sbcformdata.value.sbckeys1;
    var sendsbc = '{"action":"3cxsbc_config","uid":"' + id + '","sbc_password":"' + this.sbcformdata.value.sbcpass1 + '","tunnelAddr":"' + sbchostname + '","pbx_sip_ip":"' + sbchostname + '","prov_link":"' + prov_link + '","hostname":"' + this.hostname + '","web_url":"' + this.sbcformdata.value.sbcweb1 + '","auth_key":"' + this.sbcformdata.value.sbckeys1 + '","pbx_sip_port":"' + this.sbcformdata.value.sbcsipport1 + '","pbx_tunnel_port":"' + this.sbcformdata.value.sbctunnelport1 + '"}';
    this.websocket.send(sendsbc);

    this.sbcformdata.reset();
    setTimeout(() => { location.reload(); }, 120000);

  }


  sbc_update() {

    $('#addContactModal2').modal('hide');

    Swal.fire({

      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    //console.log(this.sbcupdate.value.sbcweb2);
    // return false;
    //console.log(this.sbcuuid);
    var wanip = this.sbcuuid.toLowerCase();
    var id = btoa('WAN=' + wanip);
    id = btoa(id);
    let SBC = $("#use_device_as_sbc" + this.sbc_index).is(":checked");

    //console.log(this.sbcupdate.value.sbcpass2);
    //console.log(SBC);
    // return false;
    var parser = document.createElement('a');
    parser.href = this.sbcupdate.value.sbcweb2;
    var sbchostname = parser.hostname;
    var prov_link = this.sbcupdate.value.sbcweb2 + "/sbc/" + this.sbcupdate.value.sbckeys2;
    var sendsbc = '{"action":"3cxsbc_config","uid":"' + id + '","sbc_password":"' + this.sbcupdate.value.sbcpass2 + '","tunnelAddr":"' + sbchostname + '","pbx_sip_ip":"' + sbchostname + '","prov_link":"' + prov_link + '","hostname":"' + this.hostname + '","web_url":"' + this.sbcupdate.value.sbcweb2 + '","auth_key":"' + this.sbcupdate.value.sbckeys2 + '","pbx_sip_port":"' + this.sbcupdate.value.sbcsipport2 + '","pbx_tunnel_port":"' + this.sbcupdate.value.sbctunnelport2 + '"}';
    this.websocket.send(sendsbc);

    this.sbcupdate.reset();

  }


  three_cxtosbc() {
    // alert("hello");
    //console.log(this.macvalue1);

    $('#addContactModal').modal('hide');

    Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80,
      imageWidth: 80,
      showConfirmButton: false,
      allowOutsideClick: false
    });
    //console.log(this.formdata.value.sbcweb);
    // return false;
    var wanip = this.macvalue1.toLowerCase();
    //console.log(wanip)
    var id1 = btoa('WAN=' + wanip);
    //console.log(id1)
    var id = btoa(id1);
    let SBC = $("#use_device_as_sbc" + this.sbc_index).is(":checked");

    //console.log(this.formdata.value.sbcpass);
    //console.log(SBC);
    // return false;
    var parser = document.createElement('a');
    parser.href = this.formdata.value.sbcweb;
    var sbchostname = parser.hostname;
    var prov_link = this.formdata.value.sbcweb + "/sbc/" + this.formdata.value.sbckeys;
    var sendsbc = '{"action":"3cxsbc_config","uid":"' + id + '","sbc_password":"' + this.formdata.value.sbcpass + '","tunnelAddr":"' + sbchostname + '","pbx_sip_ip":"' + sbchostname + '","prov_link":"' + prov_link + '","hostname":"' + this.hostname + '","web_url":"' + this.formdata.value.sbcweb + '","auth_key":"' + this.formdata.value.sbckeys + '","pbx_sip_port":"' + this.formdata.value.sbcsipport + '","pbx_tunnel_port":"' + this.formdata.value.sbctunnelport + '"}';
    this.websocket.send(sendsbc);
    //console.log("sended");

    this.formdata.reset();
    //   } else {
    //     this.errortoastForm('Failed', 'SBC Settings Failed');
    //   }
    // }, (error) => {
    //   this.errortoast('Update Error');
    //   //console.log('there was an error sending the query', error);
    // });
  }



  ipaddresslocation(ipaddress, ids) {
    // {"access_token":"","api_type":"web","operation":"getipdetails","moduleType":"license","element_data":{"action":"ipinfo","ip":"124.123.106.206"}}

    const IPReq: any = new Object();
    const Logindatalist: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'getipdetails',
      'moduleType': 'license',
      'element_data': IPReq,
    });

    IPReq.action = 'ipinfo';
    IPReq.ip = ipaddress;
    this.apiservice.newsendServer(Logindatalist).then((response: any) => {
      //console.log(response);
      $('#location' + ids).text(response.options.city);
    }, (error) => {
      //console.log(error);
      // this.apiservice.dismiss();
    });
  }
  firewalllistRedirect() {
    var id = $('#firewalllistRedirect').val()
    this.firewalllist(id);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newList, event.previousIndex, event.currentIndex);
    //console.log(event.previousIndex, event.currentIndex);
    //console.log(event);
    //console.log(this.newList);
    var testing = [];
    this.newList.forEach(element => {
      // //console.log(element.serial_number);
      // //console.log(element.serial_number.join(","));
      var mac1 = element.mac_address1;
      var mac2 = element.mac_address2;
      var sno = element.serial_number;
      var dev_model = element.device_model;


      var wanip = mac1.toLowerCase();
      var id = btoa('WAN=' + wanip);
      var testid = btoa(id);
      this.lic_id_array.push(testid);


      testing.push(element.serial_number);
      // //console.log(testing);
    });
    //console.log('serial_number:', testing.join(","));


    // if (localStorage.getItem('end_user')) {
    //   type = localStorage.getItem('end_user')
    // } else {
    //   type = '2';
    // }

    var type;
    if (this.login_types == 'D') {
      type = '2';
    } else if (this.login_types == 'R') {
      type = localStorage.getItem('end_user')
    }
    // {"access_token":"","api_type":"web",
    // "operation":"curlData","moduleType":"reseller",
    // "element_data":{"action":"sorting_license","customer_id":"5228","sno":"test1,N3X220191393,N3X220191241,test2",
    // "type":"2"}}
    const SortingReq: any = new Object();
    const Logindatalist: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': SortingReq,
    });

    SortingReq.action = 'sorting_license';
    SortingReq.customer_id = this.userId;
    SortingReq.sno = testing.join(",");
    SortingReq.type = type;
    this.apiservice.newsendServer(Logindatalist).then((response: any) => {
      //console.log(response);
    }, (error) => {

      console.log(error);
      // this.apiservice.dismiss();
    });
  }
  addCallRoutingEdit(wanmac) {
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var aiolist = '{"action":"get_analoginfo","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(aiolist);
  }
  saveRoutingGroupSaveList(wanmac) {
    //console.log(wanmac);
    var wanip = wanmac.toLowerCase();
    // //console.log(wanip);
    var id = btoa('WAN=' + wanip);

    // var id = btoa('WAN=70b3d5043244'); // 190
    // var id = btoa('WAN=70b3d5043374'); // 143
    id = btoa(id);
    var aiolist = '{"action":"get_analoginfo","hostname":"' + this.hostname + '", "uid":"' + id + '"}';
    this.websocket.send(aiolist);
  }



  nx16Device(mData, newuuid) {

    // ================================= LAN DISPLAY =======================================
    var lantoggle = mData.method;
    alert
    $("#activation" + newuuid).prop('checked', true);
    // lanstatus;
    // wanstatus;
    // interface
    var self = this;
    //console.log(mData);

    var lancontent = '';
    var newlancontent = '';
    lancontent = '<a href="#" class="active"><img src="assets/custom-images/lan-active.svg"> LAN</a>';
    newlancontent = '<a href="#" style="margin: 0px" class="active"><img style="margin: 0px;margin-left: 3px;" src="assets/custom-images/lan-active.svg"></a>';
    $(".interface-lan" + newuuid).html(lancontent);
    $("#interface-newlan" + newuuid).html(newlancontent);


    $('#fireware-version' + newuuid).text('Firmware V' + mData.current_version);
    $('#cloud_val' + newuuid).text(mData.uid);
    $('#dev_ip' + newuuid).text(mData.internetIpAddress);
    this.ipaddresslocation(mData.internetIpAddress, newuuid);

    // if (lanadd !== 'NULL' && langate !== 'NULL' && lannets !== 'NULL') {

    if (lantoggle == 'static') {
      $("#LanAssignIP" + newuuid).prop('checked', true);
      $("#LanAutoIP" + newuuid).prop('checked', false);
    } else if (lantoggle == 'dhcp') {
      $("#LanAutoIP" + newuuid).prop('checked', true);
      this.ObtainToggle(newuuid, "", "");
      $("#LanAssignIP" + newuuid).prop('checked', false);
    }
    if (mData.ipaddress != undefined && mData.ipaddress != null) {
      var arraylanadd = mData.ipaddress.split('.');
      $('#lanaddress1' + newuuid).val(arraylanadd[0]);
      $('#lanaddress2' + newuuid).val(arraylanadd[1]);
      $('#lanaddress3' + newuuid).val(arraylanadd[2]);
      $('#lanaddress4' + newuuid).val(arraylanadd[3]);
    }

    if (mData.netmask != undefined && mData.netmask != null) {
      // var arraylannets = mData.netmask.split('.');
      $('#lannetmask1' + newuuid).val(mData.netmask);
    }

    if (mData.gateway != undefined && mData.gateway != null) {
      var arraylangate = mData.gateway.split('.');
      $('#langateway1' + newuuid).val(arraylangate[0]);
      $('#langateway2' + newuuid).val(arraylangate[1]);
      $('#langateway3' + newuuid).val(arraylangate[2]);
      $('#langateway4' + newuuid).val(arraylangate[3]);
    }

    // }

    // ================================= DNS ADDRESS =====================================

    if (mData.dns != undefined && mData.dns != null) {
      var arraydnsadd = mData.dns.split('.');
      $('#dnsaddress1' + newuuid).val(arraydnsadd[0]);
      $('#dnsaddress2' + newuuid).val(arraydnsadd[1]);
      $('#dnsaddress3' + newuuid).val(arraydnsadd[2]);
      $('#dnsaddress4' + newuuid).val(arraydnsadd[3]);
    }
    // Swal.close();
  }




}
